import {strings} from '../../strings';
import {Button} from '../form';
import React from 'react';
import {withSnackbar} from 'notistack';
import {connect} from 'react-redux';
import {remove} from '../../actions/cart/cart';

const texts = strings.component.userCart

function DeleteCartItem(props) {
	const {id, remove, enqueueSnackbar, ...others} = props

	function handleDelete() {
		remove(id)
		enqueueSnackbar('Item removido com sucesso', {variant: 'error'})
	}

	return <Button
		fullWidth
		text={props.text}
		icon={texts.deleteBtn.icon}
		style={{background: 'red', color: 'white'}}
		tooltip={texts.deleteBtn.tooltip}
		onClick={handleDelete}
		{...others}
	/>
}

const actions = (dispatch) => ({
	remove: (id) => dispatch(remove(id)),
})

export default withSnackbar(connect(null, actions)(DeleteCartItem))