import React from 'react'
import PropTypes from 'prop-types'
import {Grid} from '@material-ui/core'
import generic from '../generic'

function OnePageLayout(props) {
	const {Content, spacing,} = props
	return <>
		<Grid
			item xs={12}
			container
			direction='row'
			justify='space-evenly'
			alignItems='center'
			style={generic.spacingStyle(spacing)}
		>
			{Content}
		</Grid>
	</>
}

OnePageLayout.propTypes = {
	Content: PropTypes.object,
	spacing: PropTypes.number,
}

OnePageLayout.defaultProps = {
	Content: <></>,
	spacing: generic.spacing,
}

export default OnePageLayout