import React from 'react'
import PropTypes from 'prop-types'
import {Badge, Icon, IconButton, Menu, MenuItem, Popover,} from '@material-ui/core'
import UserAuth from '../common/UserAuth';
import UserCart from '../cart/UserCart';

function MobileMenuNav(props) {
	const {anchor, open, handleClose} = props
	const mobileMenuId = 'primary-search-account-menu-mobile'

	return <>
		<Popover
			id={mobileMenuId}
			open={open}
			anchorEl={anchor}
			onClose={handleClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
		>
			<UserAuth/>
			<UserCart/>
		</Popover>
	</>
}

MobileMenuNav.propTypes = {
	anchor: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
	open: PropTypes.bool,
	handleClose: PropTypes.func,
}

MobileMenuNav.defaultProps = {
	anchor: null,
	open: false,
	handleClose: null,
}

// TODO: !!!!!!!! Connect to profile menu open
export default MobileMenuNav