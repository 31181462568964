import React from 'react'
import PropTypes from 'prop-types'
import Pagination from '../common/pagination/Pagination';
import {routes} from '../../strings';

function ProductPagination(props) {
	const {categoryId, actual, total} = props
	const link = routes.nav.shop.cat.href
	const categorizedLink = categoryId ?
		link.replace(':cat', categoryId)
		: link
	return <>
		<Pagination link={categorizedLink} actual={actual} total={total}/>
	</>
}

ProductPagination.propTypes = {
	categoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default ProductPagination