import {combineReducers} from 'redux'

import login from './auth/login'
import register from './auth/register'
import recover from './auth/recover'
import cart from './cart/cart'
import product from './product/product'
import shop from './shop/shop'
import categories from './categories/categories'
import news from './news/news'
import history from './history/history'

export default combineReducers({
	login,
	register,
	// passwordForgot,
	// passwordReset,
	cart,
	history,
	recover,
	product,
	shop,
	categories,
	news,
});