import React from 'react'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import {Grid, makeStyles} from '@material-ui/core';

const allStyles = theme => ({
	margin: '4px',
	padding: theme.spacing(1),
	width: '35px',
	height: '35px',
	fontWeight: 'bold',
	borderRadius: '50px',
	border: `solid 1px ${theme.palette.primary.main}`,
	'&:hover': {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
	},
})

const useStyles = makeStyles(theme => ({
	disabledItem: {
		border: 'none',
		color: theme.palette.primary.main,
	},
	item: {
		...allStyles(theme),
		backgroundColor: 'none',
		color: theme.palette.primary.main,
	},
	activeItem: {
		...allStyles(theme),
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
	},
}))

function PaginationItem(props) {
	const {num, disabled, active, link} = props
	const classes = useStyles()

	let style = disabled ? classes.disabledItem : classes.item
	style = active ? classes.activeItem : style

	const to = (typeof link === 'string' ? link.replace(':page', num) : '').replace(':cat', num)

	const MaybeLink = disabled ? React.Fragment : Link
	const maybeProps = disabled ? {} : {to}
	return <>
		<MaybeLink {...maybeProps}>
			<Grid
				item
				className={style}
			>
				<span>{disabled ? '•••' : (num + 1)}</span>
			</Grid>
		</MaybeLink>
	</>
}

PaginationItem.propTypes = {
	link: PropTypes.string,
	num: PropTypes.number,
	disabled: PropTypes.bool,
	active: PropTypes.bool,
}

PaginationItem.defaultProps = {
	link: ':page',
	total: 0,
	actual: false,
	limit: false,
}

export default PaginationItem