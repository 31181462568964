import PropTypes from 'prop-types'
import {strings} from '../../strings';

const defaultText = strings.component.news.news.default

export default {
	types: {
		content: {
			id: PropTypes.number.isRequired,
			title: PropTypes.string,
			datetime: PropTypes.number,
			descripion: PropTypes.string,
			author: PropTypes.string,
			authorPic: PropTypes.string,
			image: PropTypes.string,
		},
		options: {
			limit: PropTypes.number,
		},
	},
	defaults: {
		content: {
			id: 0,
			datetime: 0,
			// TODO: !!!
			authorPic: null,
			// TODO: !!!
			image: null,
			...defaultText.content,
		},
		options: {
			...defaultText.options,
		},
	}
}