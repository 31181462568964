import {remove} from '../../actions/cart/cart';
import {connect} from 'react-redux';
import {strings} from '../../strings';
import {TableCell, TableRow} from '@material-ui/core';
import {Button} from '../form';
import React from 'react';

const texts = strings.component.userCart

function ProductItem(props) {
	const {index, remove} = props
	const {product: {id, name, price}, quant,} = props.item


	function handleDelete() {
		remove(id)
	}

	// Localization
	const localizationOptions = {style: 'currency', currency: strings.currency}
	const totalPrice = (price * quant).toLocaleString(strings.lang, localizationOptions)

	return <>
		<TableRow>
			<TableCell component='th' scope='row'>{index}</TableCell>
			<TableCell align='right'>{name}</TableCell>
			<TableCell align='right'>{quant}</TableCell>
			<TableCell align='right'>{totalPrice}</TableCell>
			<TableCell align='right'>
				<Button
					icon={texts.deleteBtn.icon}
					color='secondary'
					tooltip={texts.deleteBtn.tooltip}
					onClick={handleDelete}
				/>
			</TableCell>
		</TableRow>
	</>
}

const actions = (dispatch) => ({
	remove: (id) => dispatch(remove(id)),
})
export default connect(null, actions)(ProductItem)