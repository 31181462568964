const reducerName = 'history'
const actions = (state, payload = {}) => ({
	[`${reducerName}_start`]: {...state, fetched: false, fetching: true, error: null},
	[`${reducerName}_error`]: {...state, fetched: false, fetching: false, error: payload},
	[`${reducerName}_success`]: {
		...state, fetched: true, fetching: false, error: null,
		...payload,
	},
})

export default function reducer(state = {
	fetching: false,
	fetched: false,
	error: null,
	content: [],
	pageable: {
		sort: {
			sorted: null,
			unsorted: null,
			empty: null,
		},
		pageSize: null,
		pageNumber: null,
		offset: null,
		paged: null,
		unpaged: null,
	},
	last: null,
	totalPages: null,
	totalElements: null,
	first: null,
	sort: {
		sorted: null,
		unsorted: null,
		empty: null,
	},
	numberOfElements: null,
	size: null,
	number: null,
	empty: null,
}, action) {
	return actions(state, action.payload)[action.type] || state
}