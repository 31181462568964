import React from 'react'
import PropTypes from 'prop-types'
import {DiscordWidget, TwitterWidget} from '../../components/social_networks'
import {routes} from '../../strings'
import {BasicLayout} from '../../layout'
import {makeStyles, Paper} from '@material-ui/core'
import VerticalLayout from '../../layout/vertical/VerticalLayout'
import Header from '../../components/header/Header'
import PageTitle from '../../components/common/PageTitle';


function GenericPage(props) {

	const {pageTitle, Content} = props

	const twitter = <Paper>
		<TwitterWidget screenName={routes.url.widgets.twitter.screenName}/>
	</Paper>

	const discord = <>
		<DiscordWidget id={routes.url.widgets.discord.serverId}/>
	</>

	const aside = <VerticalLayout ContentList={[twitter, discord]}/>

	return <>
		<Header/>
		<PageTitle title={pageTitle}/>
		<BasicLayout
			spacing={2}
			Content={Content}
			Aside={aside}
		/>
	</>
}

GenericPage.propTypes = {
	pageTitle: PropTypes.string,
	Content: PropTypes.object,
}

GenericPage.defaultProps = {
	pageTitle: '',
	Content: <></>,
}

export default GenericPage