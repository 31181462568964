import {routes} from '../../strings/'
import request from '../../utility/request';

const api = routes.url.api

export function getShop(type = 'root', page = 0, cat = null) {
	return async function (dispatch) {
		dispatch(start())

		const choosenApi = () => {
			switch (type) {
				case 'root':
					return api.product.shopList.href
					.replace(':page', page)
				case 'category':
					return api.product.listByCategory.href
					.replace(':page', page)
					.replace(':id', cat)
			}
		}

		const response = await request(`${api.href}${choosenApi()}`)
		const res = await response.json()
		console.log(res)
		if (response.ok) {
			dispatch(success(res))
		} else {
			dispatch(error(res.message))
		}
	}
}

export function start() {
	return {type: 'shop_start'}
}

export function error(data) {
	return {type: 'shop_error', payload: data}
}

export function success(data) {
	return {type: 'shop_success', payload: data}
}
