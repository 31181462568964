import React, {useState} from 'react'
import {Redirect} from 'react-router-dom'
import {Button} from '../../../components/form'
import {withSnackbar} from 'notistack'
import {Grid, Paper, TextField} from '@material-ui/core';
import Loading from '../../../components/common/Loading';
import ErrorFeedback from '../../../components/common/ErrorFeedback';
import {routes} from '../../../strings/'
import {loginClear} from '../../../actions/auth/login';
import {connect} from 'react-redux';

function ResetPasswordModal(props) {
	const [values, setValues] = useState({currentPassword: '', newPassword: ''})
	const [success, setSuccess] = useState(false)
	const [loading, setLoading] = useState(false)
	const [redirect, setRedirect] = useState(null)
	const [error, setError] = useState('')
	const {enqueueSnackbar} = props

	async function handleSubmit() {
		setLoading(true)
		const options = {
			method: 'POST',
			headers: {
				'Authorization': `Bearer ${localStorage.token}`,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				currentPassword: values.currentPassword,
				newPassword: values.newPassword
			})
		}

		const res = await fetch(routes.url.api.href + routes.url.api.changepassword.href, options)
		if (res.ok) {
			enqueueSnackbar('Senha redefinida com sucesso!', {variant: 'success'})
			setSuccess(true)
			props.clear()
			setRedirect(routes.nav.home.root.href)
		} else {
			const resJson = await res.json()
			console.log(resJson)
			setError(resJson.message)
			setLoading(false)
			enqueueSnackbar('Erro ao redefinir senha!', {variant: 'error'})
		}
	}

	function handleChange(e) {
		setValues({...values, [e.target.name]: e.target.value})
	}

	if (success)
		return <Paper style={{padding: '16px'}}>
			{redirect && <Redirect to={redirect}/>}
			<p>Senha redefinida com sucesso! :)</p>
		</Paper>

	return <Paper style={{padding: '16px'}}>
		<Loading loading={loading}/>
		<Grid container alignItems='center' justify='center'
			// className={classes.modal}
		>
			<TextField
				fullWidth
				value={values.currentPassword}
				label='Senha atual'
				name='currentPassword'
				type='password'
				margin='normal'
				variant='outlined'
				disabled={loading}
				onChange={handleChange}
			/>
			<TextField
				fullWidth
				value={values.newPassword}
				label='Nova senha'
				name='newPassword'
				type='password'
				margin='normal'
				variant='outlined'
				disabled={loading}
				onChange={handleChange}
			/>
			<ErrorFeedback message={error}/>
			<Button
				// fullWidth
				type='submit'
				// className={classes.button}
				variant='contained'
				children={'Entrar'}
				color='secondary'
				text='Redefinir senha'
				disabled={loading}
				onClick={handleSubmit}
			/>
		</Grid>
		<Loading loading={loading}/>
	</Paper>
}


const actions = dispatch => ({
	clear: () => dispatch(loginClear()),
})

export default withSnackbar(connect(null, actions)(ResetPasswordModal))