import {strings} from '../../strings';
import React from 'react';


function Money(props){
	const {children} = props
	const localizationOptions = {style: 'currency', currency: strings.currency}
	const localized = children.toLocaleString(strings.lang, localizationOptions)
	return <>{localized}</>
}

export default Money