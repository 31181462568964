import React from 'react'
import PropTypes from 'prop-types'
import {Grid} from '@material-ui/core'
import generic from '../generic'

function VerticalLayout(props) {
	const {ContentList, spacing,} = props
	return <>
		<Grid
			container
			direction='column'
			justify='space-between'
			alignItems='stretch'
			spacing={spacing}
		>
			{ContentList.map((Item, i) =>
				<Grid item key={`vl-${i}`}>
					{Item}
				</Grid>
			)}

		</Grid>
	</>
}

VerticalLayout.propTypes = {
	ContentList: PropTypes.arrayOf(PropTypes.object),
	spacing: PropTypes.number,
}

VerticalLayout.defaultProps = {
	ContentList: [],
	spacing: generic.spacing,
}

export default VerticalLayout