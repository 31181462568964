import React, {useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {AppBar, Grid,} from '@material-ui/core'
import PageNavItem from './PageNavItem'
import {routes} from '../../strings';
import {withSnackbar} from 'notistack';
import {connect} from 'react-redux';
import {getCategories} from '../../actions/categories/categories';

const useStyles = makeStyles(theme => ({
	pageNavbar: {
		padding: theme.spacing(1),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		flexGrow: 1,
		background: theme.palette.consonant.main,
		color: theme.palette.consonant.contrastText,
	},
	grow: {
		flexGrow: 1,
	},
	// title: {
	// 	display: 'none',
	// 	margin: '12px 26px 12px 2px',
	// 	[theme.breakpoints.up('md')]: {
	// 		display: 'block',
	// 	},
	// },
}))

function DesktopNav(props) {

	const {list} = props

	return <>
		<Grid container>
			<Grid item xs={12} sm={4} md={3}>
				<PageNavItem
					to={routes.nav.home.root.href}
					icon={'https://cdn2.iconfinder.com/data/icons/minecraft-and-pixels/32/minecraft-03-512.png'}
					title={'Página Inicial'}
					active={false}
					id={Math.floor(Math.random() * 100)}
				/>
			</Grid>
			{/*<Grid item xs={12} sm={4} md={3}>*/}
			{/*	<PageNavItem*/}
			{/*		to={routes.nav.shop.root.href}*/}
			{/*		icon={'https://cdn2.iconfinder.com/data/icons/minecraft-and-pixels/32/minecraft-03-512.png'}*/}
			{/*		title={'Loja'}*/}
			{/*		active*/}
			{/*		id={Math.floor(Math.random() * 100)}*/}
			{/*	/>*/}
			{/*</Grid>*/}
			<PageNavbarList list={list.filter(i => i.parent_id == null)}/>
		</Grid>
	</>
}

function PageNavbarList(props) {
	const {list} = props

	return <>
		{list.map(item => <Grid item xs={12} sm={4} md={3} key={`category-${item.id}`}>
			<PageNavItem
				icon={item.icon}
				title={item.name}
				active={false}
				id={item.id}
			/>
		</Grid>)}
	</>
}

function PageNavbar(props) {
	const classes = useStyles()

	const {
		categories: {content: list, error, fetching},
		get,
	} = props

	const [hasLoad, setHasLoad] = useState(false)
	if (!hasLoad) {
		setHasLoad(true)
		get()
	}

	return <>
		<div className={classes.grow}>
			<AppBar
				position='static'
				className={classes.pageNavbar}
			>
				<DesktopNav list={list}/>
			</AppBar>
		</div>
	</>
}

PageNavbar.propTypes = {}

const actions = (dispatch) => ({
	get: () => dispatch(getCategories()),
})

export default withSnackbar(connect(store => ({categories: store.categories}), actions)(PageNavbar))