import {routes} from '../../strings/'
import request from '../../utility/request';

const api = routes.url.api


export function doRecover(email) {
	return async function (dispatch) {
		dispatch(start())

		const response = await request(`${api.href}${api.recover.href}`, 'POST', {
			email,
		})
		if (response.ok) {
			dispatch(success())
		} else {
			const res = await response.json()
			console.log(res)
			dispatch(error(res.message))
		}
	}
}


export function start() {
	return {type: 'recover_start'}
}

export function error(data) {
	return {type: 'recover_error', payload: data}
}

export function success() {
	return {type: 'recover_success'}
}