import {makeStyles} from '@material-ui/core';

export default makeStyles(theme => ({
	image: {
		height: 240,
	},
	viewTitle: {
		fontWeight: 'bold',
	},
	viewDesc: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
	},
	viewImage: {
		padding: theme.spacing(2),
		minHeight: '65vh',
	},
	infoContainer: {
		padding: theme.spacing(2),
	},
	priceContainer: {},
	oldPrice: {
		fontWeight: 'bold',
		fontSize: '12px',
		textDecoration: 'line-through',
		color: 'gray',
	},
	price: {
		lineHeight: 1,
		fontWeight: 300,
		fontSize: '20px',
		color: theme.palette.primary.main,
	},
	oldPriceView: {
		fontWeight: 'bold',
		fontSize: '20px',
		textDecoration: 'line-through',
		color: 'gray',
	},
	priceView: {
		lineHeight: 1,
		fontWeight: 300,
		fontSize: '28px',
		color: theme.palette.primary.main,
	},
}))