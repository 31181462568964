import React from 'react'
import PropTypes from 'prop-types'
import {Grid} from '@material-ui/core'
import generic from '../generic'

function BasicLayout(props) {
	const {Content, Aside, spacing, rightSize} = props
	return <>
		<Grid
			container
			direction='row'
			justify='space-evenly'
			alignItems='flex-start'
		>
			<Grid
				style={generic.spacingStyle(spacing)}
				item
				xs={12}
				md={(12 - rightSize)}
			>
				{Content}
			</Grid>
			<Grid
				style={generic.spacingStyle(spacing)}
				item
				xs={12}
				md={rightSize}
			>
				{Aside}
			</Grid>

		</Grid>
	</>
}

BasicLayout.propTypes = {
	Content: PropTypes.object,
	Aside: PropTypes.object,
	spacing: PropTypes.number,
	rightSize: PropTypes.number,
}

BasicLayout.defaultProps = {
	Content: <></>,
	Aside: <></>,
	spacing: generic.spacing,
	rightSize: generic.rightSize,
}

export default BasicLayout