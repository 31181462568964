import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { CardActionArea, CardMedia, Grid, Paper, Typography } from '@material-ui/core';
import style from './style'
import { strings } from '../../strings';
import AddCartBtn from '../cart/AddCartBtn';
import DeleteCartItem from '../cart/DeleteCartItem';
import './../common/htmlView.css'

function Product(props) {
	const classes = style()
	const {
		content,
		content: { id, name, icon, description, oldPrice, price, },
		cart: { items }
	} = props

	const isOnCart = !!items.filter(item => item.product.id === id).length
	const [bought, setBought] = useState(isOnCart)

	function switchBought() {
		setBought(!bought)
	}

	const [quant, setQuant] = useState(1)

	function handleQuant(e) {
		e.preventDefault()
		setQuant(e.target.value)
	}

	// Localization
	const localizationOptions = { style: 'currency', currency: strings.currency }
	const lOldPrice = oldPrice ? oldPrice.toLocaleString(strings.lang, localizationOptions) : null
	const lPrice = price ? price.toLocaleString(strings.lang, localizationOptions) : null
	return <>
		<Paper>
			<article key={`product-item-${id}`}>
				<Grid container>
					<Grid item xs={12} md={5}>
						<CardActionArea>
							<CardMedia
								className={classes.viewicon}
								image={icon}
								name={name}
								component='img'
							/>
						</CardActionArea>
					</Grid>
					<Grid
						container
						item
						xs={12} md={7}
						className={classes.infoContainer}
						spacing={2}
						direction='column'
					>
						<Typography gutterBottom variant='h2' component='h1' className={classes.viewname}>
							{name}
						</Typography>
						<div className={classes.priceContainer}>
							{oldPrice && <div className={classes.oldPriceView}>{lOldPrice}</div>}
							<div className={classes.priceView}>{lPrice}</div>
						</div>
						<Typography variant='body2' color='textSecondary' component='p' className={classes.viewDesc}>
							<div dangerouslySetInnerHTML={{__html: description}}/>
						</Typography>
						{/*<TextField*/}
						{/*	value={quant}*/}
						{/*	onChange={handleQuant}*/}
						{/*	type="number"*/}
						{/*	className={classes.qntInput}*/}
						{/*	InputProps={{*/}
						{/*		inputProps: {*/}
						{/*			min: 0,*/}
						{/*			max: 999999,*/}
						{/*		},*/}
						{/*	}}*/}
						{/*	margin="normal"*/}
						{/*	variant="outlined"*/}
						{/*/>*/}
						{bought ?
							<div onClick={switchBought}><DeleteCartItem id={id} /></div>
							: <div onClick={switchBought}><AddCartBtn product={content} text='Remover do carrinho' onClick={switchBought} /></div>}
					</Grid>
				</Grid>
			</article>
		</Paper>
	</>
}


Product.propTypes = {
	content: PropTypes.shape({
		id: PropTypes.string,
		name: PropTypes.string,
		icon: PropTypes.string,
		description: PropTypes.string,
		oldPrice: PropTypes.number,
		price: PropTypes.number,
	}),
}

Product.defaultProps = {
	content: {
		id: 0,
		name: '',
		icon: '',
		description: '',
		oldPrice: 0,
		price: 0,
	},
}

export default connect(store => ({ cart: store.cart }), null)(Product)