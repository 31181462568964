const reducerName = 'product'
const actions = (state, payload = {}) => ({
	[`${reducerName}_start`]: {...state, fetched: false, fetching: true, error: null},
	[`${reducerName}_error`]: {...state, fetched: false, fetching: false, error: payload},
	[`${reducerName}_success`]: {...state, fetched: true, fetching: false, error: null, actual: payload},
})

export default function reducer(state = {
	fetching: false,
	fetched: false,
	error: null,
	actual: {
		id: null,
		name: null,
		description: null,
		icon: null,
		price: null,
		arguments: null,
	},
}, action) {
	return actions(state, action.payload)[action.type] || state
}