import React from 'react'
import {Grid, TextField} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Button} from '../form';
import {modalEnum} from './AuthManager';
import AuthModal from './AuthModal';
import {strings} from '../../strings';
import {connect} from 'react-redux';
import Loading from '../common/Loading';
import {doRecover} from "../../actions/auth/recover";

const useStyles = makeStyles(theme => ({
	modal: {
		// width: '330px',
		padding: theme.spacing(1),
		textAlign: 'center',
	},
	title: {
		textAlign: 'center',
		fontWeight: 300,
		paddingTop: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		fontSize: '24px',
		color: 'gray',
	},
	button: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	feedback: {
		margin: theme.spacing(1),
		textAlign: 'center',
		color: theme.palette.success.main,
		width: '100%',
		fontSize: '24px',
	}
}))

const texts = strings.component.forgotPasswordModal
const footerTexts = strings.component.authModalFooter

function ForgotPasswordModal(props) {
	const classes = useStyles()
	const {switchAuth, dispatch} = props
	const {recover: {fetching, fetched, error}} = props
	const [success, setSuccess] = React.useState(false)

	React.useEffect(() => {
		console.log(fetched, error)
		setSuccess(fetched && !error)
	}, [fetched, error])

	const onSubmit = (e) => {
		e.preventDefault()
		const data = new FormData(e.currentTarget);
		dispatch(doRecover(data.get('email')))
	}

	const main = <>
		<Loading loading={fetching}/>
		<form
			onSubmit={onSubmit}
		>
			<Grid container alignItems='center' justify='center'
						className={classes.modal}
			>
				{success && <p className={classes.feedback}>Enviamos um e-mail com as instruções para recuperar sua senha.</p>}
				{!success && <>
						<TextField
						id={"email"}
						required
						type={'email'}
						fullWidth
						label='Email'
						name="email"
						placeholder='seuemail@gmail.com'
						margin='normal'
						variant='outlined'
					/>
					<Button
						type={"submit"}
						className={classes.button}
						text={'Enviar'}
						color='secondary'
						disabled={fetching}
					/>
				</>}
			</Grid>
		</form>
	</>

	const header = !success ? <>
		<h1 className={classes.title}>
			{texts.header}
		</h1>
		<p>{texts.subheader}</p>
	</> : <></>

	const footer = !success ? <>
		<div>{footerTexts.login.message} <b onClick={() => switchAuth(modalEnum.login)}>{footerTexts.login.linked}</b>
		</div>
		<div>{footerTexts.signup.message} <b
			onClick={() => switchAuth(modalEnum.signup)}>{footerTexts.signup.linked}</b></div>
	</> : <></>

	return <>
		<AuthModal
			title={texts.title}
			header={header}
			main={main}
			footer={footer}
		/>
	</>
}

export default connect((store) => {
	return {
		recover: store.recover,
	};
})(ForgotPasswordModal)