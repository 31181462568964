import {routes} from '../../strings';
import {Link} from 'react-router-dom'
import {
	Avatar,
	Grid,
	IconButton,
	ListItem,
	ListItemAvatar,
	ListItemText,
	makeStyles,
	Menu,
	MenuItem
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import {withSnackbar} from 'notistack';
import {connect} from 'react-redux';
import {Button} from '../form';
import SubMenu from './SubMenu';

const useStyles = makeStyles(theme => ({}))

function PageNavItem(props) {
	const {categories: {content: list}, id, to, title, icon, active} = props
	const classes = useStyles(active)

	const subcategories = list.filter(i => id == i.parent_id)

	const href = to || routes.nav.shop.cat.href
	.replace(':cat', id)
	.replace(':page', 0)
	return <>
		<Grid container alignItems={'center'}>
			<Grid item xs={10}>
				<Link to={href}>
					<ListItem button>
						<ListItemAvatar>
							<Avatar alt={title} src={icon}/>
						</ListItemAvatar>
						<ListItemText primary={title}/>
					</ListItem>
				</Link>
			</Grid>
			<Grid item xs={2}>
				{subcategories.length > 0 && <Button
					Component={IconButton}
					PopperComponent={Menu}
					PopperContent={<SubMenu subcategories={subcategories}/>}
					tooltip={'Ver subcategorias'}
					icon={'keyboard_arrow_down'}
				/>}
			</Grid>
		</Grid>
	</>
}

PageNavItem.propTypes = {
	id: PropTypes.number,
	to: PropTypes.string,
	title: PropTypes.string,
	icon: PropTypes.string,
	active: PropTypes.bool,
}

export default withSnackbar(connect(store => ({categories: store.categories}))(PageNavItem))