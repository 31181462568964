import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {Grid, Paper} from '@material-ui/core/index';
import {makeStyles} from '@material-ui/styles/index';
import {strings} from '../../strings';

const useStyles = makeStyles(theme => ({
	onlinePlayers: {
		textTransform: 'uppercase',
		textAlign: 'center',
		// paddingBottom: '8px',
		backgroundColor: theme.palette.contrast.main,
		color: theme.palette.contrast.contrastText,
	},
	h1: {
		padding: '16px',
		fontWeight: 700,
		fontSize: '34px',
	},
	p: {
		fontWeight: 700,
		fontSize: '14px',
	},
	focus: {
		fontWeight: 700,
		fontSize: '19px',
	},
}))

const texts = strings.component.onlineplayers

function OnlinePlayers(props) {
	const classes = useStyles()
	const {value} = props

	const [count, setCount] = useState('...')
	const [started, setStarted] = useState(false)

	async function get() {
		const res = await fetch('https://api.mcsrvstat.us/2/redescreen.com', {method:'GET'})
		const json = await res.json()
		setCount(json.players.online)
	}

	if (!started) {
		setInterval(get, 10000)
		setStarted(true)
	}

	return <>
		<Paper className={classes.onlinePlayers}>
			<Grid
				container
				direction='row'
				justify='center'
				alignContent='center'
				alignItems='center'
			>
				<Grid item xs={4}>
					<div className={classes.h1}>{count}</div>
					{/*<div className={classes.h1}>{value}</div>*/}
				</Grid>
				<Grid item xs={8}>
					<div className={classes.p}>
						{texts.message.label}
					</div>
					<div className={classes.focus}>
						{texts.message.focus}
					</div>
				</Grid>
			</Grid>
		</Paper>
	</>
}

OnlinePlayers.propTypes = {
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

OnlinePlayers.defaultProps = {
	value: 0,
}

export default OnlinePlayers