import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import {strings} from '../../strings';
import {Button} from '../form';
import HowToPlayModal from './HowToPlayModal';
import {isWidthUp} from '@material-ui/core/withWidth';
import {withWidth} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	modal: {
		width: '400px',
		padding: theme.spacing(2),
	},
}))

const texts = strings.component.howtoplay

function HowToPlay(props) {
	const {width, } = props
	const classes = useStyles()

	return <>
		<Button
			PopperContent={<HowToPlayModal/>}
			className={classes.howtoplay}
			text={isWidthUp('sm', width) ? texts.btn.label : ''}
			tooltip={texts.btn.tooltip}
			icon={texts.btn.icon}
			color='secondary'
		/>
	</>
}

HowToPlay.propTypes = {}

HowToPlay.defaultProps = {}

export default withWidth()(HowToPlay)