const reducerName = 'cart'
const actions = (state, payload = {}) => ({
	[`${reducerName}_add`]: {...state, items: payload},
	[`${reducerName}_remove`]: {
		...state, items: Array.isArray(state.items) ? state.items.filter(item => item.product.id !== payload.id) : []
	},
})

export default function reducer(state = {
	items: [],
}, action) {
	return actions(state, action.payload)[action.type] || state
}