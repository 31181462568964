import React, {useState} from 'react'
import LoginModal from './LoginModal';
import ForgotPasswordModal from './ForgotPasswordModal';
import SignupModal from './SignupModal';
import FinishRecoverModal from "./FinishRecoverModal";

function AuthManager(props) {
	const {openPage} = props;
	const [page, setPage] = useState(!!openPage ? openPage : modalEnum.login)

	function switchAuth(num) {
		setPage(num)
	}

	return <>
		{page === modalEnum.login && <LoginModal switchAuth={switchAuth}/>}
		{page === modalEnum.signup && <SignupModal switchAuth={switchAuth}/>}
		{page === modalEnum.forgotPassword && <ForgotPasswordModal switchAuth={switchAuth}/>}
		{page === modalEnum.finishRecover && <FinishRecoverModal switchAuth={switchAuth}/>}
	</>
}

export const modalEnum = {
	login: 0,
	signup: 1,
	forgotPassword: 2,
	finishRecover: 3,
}
export default AuthManager