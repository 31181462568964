import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import {modalEnum} from './AuthManager';
import AuthModal from './AuthModal';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import {strings} from '../../strings';
import LoginForm from './forms/LoginForm';
import {doLogin} from '../../actions/auth/login';
import {connect} from 'react-redux';

const useStyles = makeStyles(theme => ({
	modal: {
		// width: '330px',
		padding: theme.spacing(1),
		textAlign: 'center',
	},
	title: {
		textAlign: 'center',
		fontWeight: 300,
		paddingTop: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		fontSize: '24px',
		color: 'gray',
	},
	button: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
}))

const validation = Yup.object().shape({
	email: Yup.string().trim()
	.email(strings.validation.email)
	.required(strings.validation.required),
	password: Yup.string().trim()
	.min(3, strings.validation.min)
	.max(50, strings.validation.max)
	.required(strings.validation.required),
})

const texts = strings.component.loginModal
const footerTexts = strings.component.authModalFooter

function LoginModal(props) {
	const classes = useStyles()
	const {switchAuth, dispatch} = props

	const initialValues = {}

	function onSubmit(values, {setSubmitting}) {
		setSubmitting(false)
		const {email, password,} = values
		dispatch(doLogin(email, password))
	}

	const header = <>
		<h1 className={classes.title}>
			{texts.headerText}
		</h1>
	</>

	const main = <>
		<Formik
			initialValues={initialValues}
			onSubmit={onSubmit}
			validateOnBlur
			validationSchema={validation}
			render={() => (<Form>
				<LoginForm/>
			</Form>)}
		/>
	</>

	const footer = <>
		<div>{footerTexts.signup.message} <b
			onClick={() => switchAuth(modalEnum.signup)}>{footerTexts.signup.linked}</b></div>
		<div><b onClick={() => switchAuth(modalEnum.forgotPassword)}>{footerTexts.forgotPassword.linked}</b></div>
	</>

	return <>
		<AuthModal
			title={texts.title}
			header={header}
			main={main}
			footer={footer}
		/>
	</>
}

export default connect(() => ({}))(LoginModal)
