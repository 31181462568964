import React from 'react'
import {loginToken} from '../../actions/auth/login';
import {connect} from 'react-redux';

class TokenWatcher extends React.Component {
	componentDidMount() {
		const {dispatch} = this.props
		const storageToken = localStorage.getItem('token')
		if (storageToken) {
			dispatch(loginToken(storageToken))
		}
	}

	render() {
		return <></>
	}
}

export default connect(() => {
	return {};
})(TokenWatcher)
