import React, { useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { strings } from '../../strings';
import { Button } from '../form';
import { Dialog, IconButton, Popover } from '@material-ui/core';
import LoggedUser from './LoggedUser';
import AuthManager from '../auth/AuthManager';
import { connect } from 'react-redux';
import routes from "../../strings/routes";

const useStyles = makeStyles(theme => ({
	userAuth: {
		// display: 'none',
		// [theme.breakpoints.up('md')]: {
		// 	display: 'block',
		// },
	},
}))

const texts = strings.component.userAuth

// Temporary

function UserAuth(props) {
	const { login: { token }, } = props
	const classes = useStyles()

	let [open, setOpen] = React.useState(false)
	let [openPage, setOpenPage] = React.useState(null)

	React.useEffect(() => {
		for (let popup of routes.nav.popups) {
			if (window.location.href.includes(popup.url)) {
				setOpen(true);
				setOpenPage(popup.popupPage)
			}
		}
	}, [])

	const logged = <Button
		key={Math.random()}
		Component={IconButton}
		PopperComponent={Popover}
		PopperContent={<LoggedUser />}
		className={classes.userAuth}
		tooltip={texts.btn.loggedTooltip}
		icon={texts.btn.icon}
		iconSize='32px'
	/>

	const unlogged = <Button
		key={Math.random()}
		Component={IconButton}
		PopperComponent={Dialog}
		openOnFirstRender={open}
		PopperContent={<AuthManager openPage={openPage} />}
		className={classes.userAuth}
		tooltip={texts.btn.tooltip}
		icon={texts.btn.icon}
		iconSize='32px'
	/>

	return <>
		{token ? logged : unlogged}
	</>
}

UserAuth.propTypes = {}

UserAuth.defaultProps = {}

export default connect((store) => {
	return {
		login: store.login,
	};
})(UserAuth)