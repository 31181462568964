import {Card, CardActionArea, CardContent, CardMedia, makeStyles, Tooltip, Typography} from '@material-ui/core';
import React from 'react';


const useStyles = makeStyles(theme => ({
	info: {
		margin: theme.spacing(4),
	},
	image: {
		width: '90%',
		marginLeft: '5%'
	},
	paymentItem: props => ({
		textAlign: 'center',
		border: (props.active ? `solid 5px ${theme.palette.contrast.main}` : 'none'),
		// padding: (props.active ? '8px' : '8px'),
		color: props.active ? 'white' : 'black',
		transition: '.2s',
		opacity: props.disabled ? .5 : 1,
	}),
	content: props => ({
		textAlign: 'center',
		background: (!props.active ? 'white' : theme.palette.contrast.main),
	}),
}))

function PaymentItem(props) {
	const classes = useStyles(props)
	const {name, image, disabled} = props
	return <>
		<Tooltip title={disabled ? 'Indisponível' : ''}>
			<Card className={classes.paymentItem} {...props}>
				<CardActionArea>
					<CardMedia
						className={classes.image}
						component="img"
						image={image}
						title={name}
					/>
					<CardContent className={classes.content}>
						<Typography gutterBottom variant='h5' component='h2'>
							{name}
						</Typography>
					</CardContent>
				</CardActionArea>
			</Card>
		</Tooltip>
	</>
}

export default PaymentItem