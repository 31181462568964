import React from 'react'
import {Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {strings} from '../../strings';
import PageNavbar from '../page_navbar/PageNavbar';

const texts = strings.component.shopHeader
const images = strings.image.shopHeader

const useStyles = makeStyles(theme => ({
	header: {
		padding: theme.spacing(4),
		width: '100%',
		background: `linear-gradient(0deg, rgba(0, 0, 0, 0.62), rgba(0, 0, 0, 0.62)), url('${images.background.url}')`,
		backgroundSize: 'auto 100%',
		backgroundPosition: 'center',
		color: theme.palette.consonant.contrastText,
		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(14),
			backgroundSize: '100%',
		},
	},
	text: {
		textAlign: 'justify',
		width: '50%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	h1: {
		fontWeight: 700,
		fontSize: '64px',
		textTransform: 'uppercase',
		textAlign: 'center',
	},
	p: {
		fontSize: '24px',
		marginBottom: theme.spacing(2),
		[theme.breakpoints.up('md')]: {
			marginBottom: 0,
		},
	},
}))

function ShopHeader() {
	const classes = useStyles()

	return <>
		<header>
			<Grid
				className={classes.header}
				container
				item
				direction='row'
				justify='center'
				alignItems='center'
			>
				<aside className={classes.text}>
					<h1 className={classes.h1}>
						{texts.title.label}
					</h1>
					<p className={classes.p}>
						{texts.desc.label}
					</p>
				</aside>
			</Grid>
		</header>
		<PageNavbar/>
	</>
}

export default ShopHeader