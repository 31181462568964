import {makeStyles} from '@material-ui/core';

export default makeStyles(theme => ({
	header: {
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.primary.contrastText,
	},
	title: {
		marginRight: theme.spacing(2),
		marginLeft: theme.spacing(2),
		marginBottom: theme.spacing(1),
		fontSize: '36px',
		borderBottom: `solid ${theme.palette.primary.main} 1px`,
	},
	datetime: {
		marginTop: theme.spacing(1),
		marginLeft: theme.spacing(2),
		fontSize: '14px',
		color: theme.palette.primary.text,
	},
	authorContainer: {
		padding: theme.spacing(2),
		backgroundColor: theme.palette.primary.main,
	},
	author: {
		textAlign: 'center',
		fontSize: '16px',
		lineHeight: 1.5,
		fontWeight: 'bold',
		color: theme.palette.primary.light,
		display: 'block',
		[theme.breakpoints.down('sm')]: {
			marginLeft: theme.spacing(2),
		},
	},
	authorPic: {
		width: '100px',
		maxWidth: '100%',
		borderRadius: '8px',
		border: `solid ${theme.palette.primary.light} 3px`,
		display: 'block',
	},
	image: {
		maxWidth: '100%',
		'& img': {
			padding: '16px',
			maxWidth: '100%',
			boxSizing: 'border-box',
		}
	},
	textContainer: {
		padding: theme.spacing(2),
	},
	text: {
		padding: '16px',
		fontSize: '24px',
		textAlign: 'justify',
		color: 'black',
		minHeight: '100px',
		// '&:first-letter': {
		// 	color: theme.palette.consonant.main,
		// 	float: 'left',
		// 	lineHeight: 1,
		// 	fontWeight: 'bold',
		// 	fontSize: '64px',
		// 	margin: '0 16px',
		// },
	},
	footer: {
		padding: '16px',
	}
}))