import {Button} from '../form'
import PropTypes from 'prop-types'
import React from 'react'
import {strings} from '../../strings'
import {connect} from 'react-redux';
import {add, remove} from '../../actions/cart/cart';
import { withSnackbar } from 'notistack'

const compTexts = strings.component
const texts = strings.component.products.products

function AddCartBtn(props) {
	const {iconOnly, product, quant, add, enqueueSnackbar} = props

	function handleAdd(){
		add(product, quant)
		enqueueSnackbar(compTexts.userCart.successAdd, {variant: 'success'})
	}

	return <>
		<Button
			fullWidth
			icon={texts.addCart.icon}
			text={!iconOnly ? texts.addCart.label : null}
			color='secondary'
			tooltip={iconOnly ? texts.addCart.label : ''}
			onClick={handleAdd}
		/>
	</>
}

AddCartBtn.propTypes = {
	iconOnly: PropTypes.bool,
	product: PropTypes.object,
	quant: PropTypes.oneOfType([PropTypes.number,PropTypes.string]),
}

AddCartBtn.defaultProps = {
	iconOnly: false,
	product: null,
	quant: 1,
}

const actions = (dispatch) => ({
	add: (product, quant) => dispatch(add(product, quant)),
})

export default withSnackbar(connect(null, actions)(AddCartBtn))