import {Grid, makeStyles, Typography} from '@material-ui/core';
import {routes, strings} from '../../strings';
import React, {useState} from 'react';
import PaymentItem from './PaymentItem';

const useStyles = makeStyles(theme => ({
	info: {
		margin: theme.spacing(4),
	},
}))

function PaymentMethod(props) {
	const classes = useStyles()
	const {selected, setSelected} = props

	const [once, setOnce] = useState(false)
	const [gateways, setGateways] = useState([])
	async function loadGateways(){
		const options = {
			method: 'GET',
			headers: {
				'Authorization': `Bearer ${localStorage.token}`,
			},
		}

		const res = await fetch(routes.url.api.href + routes.url.api.gateways.href, options)
		const resJson = await res.json()
		console.log(options, resJson)
		if (res.ok) {
			setGateways(resJson)			
			console.log(gateways)
		}
	}

	if(!once){
		loadGateways()
		setOnce(true)
	}

	console.log('!!!!!!!!!!', gateways)

	const methods = [
		{
			id: 'MERCADOPAGO',
			name: 'PIX, Cartão e Saldo',
			image: 'https://logodownload.org/wp-content/uploads/2019/06/mercado-pago-logo.png',
		},
		{
			id: 'PAYPAL',
			name: 'Estrangeiros e Cartão',
			image: 'https://logodownload.org/wp-content/uploads/2014/10/paypal-logo-3.png',
		},
		{
			id: 'PAGSEGURO',
			name: 'Pagseguro',
			image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/80/Logo_PagSeguro.png/1200px-Logo_PagSeguro.png',
		},
		{
			id: 'PICPAY',
			name: 'PicPay',
			image: 'https://logodownload.org/wp-content/uploads/2018/05/picpay-logo-2.png',
		},
		{
			id: 'JUNO',
			name: 'Pix',
			image: 'https://logodownload.org/wp-content/uploads/2020/02/pix-bc-logo-1.png',
		},
		{
			id: 'PAGARME',
			name: 'Pix e Cartão',
			image: 'https://dka575ofm4ao0.cloudfront.net/pages-transactional_logos/retina/1949/PagarMe_Logo_PRINCIPAL-02.png',
		},
		{
			id: 'PAGHIPER',
			name: 'Pix',
			image: 'https://logodownload.org/wp-content/uploads/2020/02/pix-bc-logo-1.png',
		},
		{
			id: 'STRIPE',
			name: 'Estrangeiros, Cartão e Pix',
			image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Stripe_Logo%2C_revised_2016.svg/2560px-Stripe_Logo%2C_revised_2016.svg.png',
		},
		{
			id: 'OPENPIX',
			name: 'Pix com Cashback',
			image: 'https://logodownload.org/wp-content/uploads/2020/02/pix-bc-logo-1.png',
		},
	]

	return <>
		<Typography variant='h3' className={classes.title}>
			Meios de pagamento
		</Typography>
		<Typography variant='h6'>
			Selecione um meio de pagamento
		</Typography>
		<section className={classes.info}>

			<Grid container justify='center' alignItems='center' spacing={4}>
				{methods.filter(m => gateways.indexOf(m.id) !== -1).map(m =>
					<Grid item xs={12} sm={4} md={4} key={`method-${m.id}`}>
						<PaymentItem active={selected === m.id}
									 disabled={gateways.indexOf(m.id) === -1}
									 name={m.name}
									 image={m.image}
									 onClick={() => {if(gateways.indexOf(m.id) !== -1) setSelected(m.id)}}
						/>
					</Grid>
				)}
			</Grid>
		</section>
	</>
}

export default PaymentMethod