import React from 'react'
import * as Yup from 'yup'
import {strings} from '../../strings'
import makeStyles from '@material-ui/core/styles/makeStyles'
import {modalEnum} from './AuthManager'
import AuthModal from './AuthModal'
import {Form, Formik} from 'formik'
import SignupForm from './forms/SignupForm'
import {doRegister} from '../../actions/auth/register'
import {connect} from 'react-redux';

const useStyles = makeStyles(theme => ({
	modal: {
		padding: theme.spacing(1),
		textAlign: 'center',
	},
	title: {
		textAlign: 'center',
		fontWeight: 300,
		paddingTop: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		fontSize: '24px',
		color: 'gray',
	},
	button: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
}))

const validation = Yup.object().shape({
	name: Yup.string().trim()
	.min(3, strings.validation.min)
	.max(255, strings.validation.max)
	.required(strings.validation.required),
	email: Yup.string().trim()
	.email(strings.validation.email)
	.required(strings.validation.required),
	password: Yup.string().trim()
	.min(3, strings.validation.min)
	.max(50, strings.validation.max)
	.required(strings.validation.required),
	passwordConf: Yup.string()
	.oneOf([Yup.ref('password'), null], strings.validation.identical)
	.required(strings.validation.required),
})

const texts = strings.component.signupModal
const footerTexts = strings.component.authModalFooter

function SignupModal(props) {
	const classes = useStyles()
	const {register: {fetched, response}, switchAuth, dispatch} = props
	console.log(response)

	const initialValues = {}

	function onSubmit(values, {setSubmitting}) {
		setSubmitting(false)
		const {name, email, password, marketing,} = values
		dispatch(doRegister(name, email, password, marketing))
	}

	const header = <>
		<h1 className={classes.title}>
			{fetched ? texts.successHeader : texts.header}
		</h1>
	</>

	const main = <>
		<Formik
			initialValues={initialValues}
			onSubmit={onSubmit}
			validateOnBlur
			validationSchema={validation}
			render={() => (<Form>
				{
					!fetched
						? <SignupForm/>
						: response.status == 'PENDING' ? <span>{texts.successMessagePending}</span> :  <span>{texts.successMessageComplete}</span>
				}
			</Form>)}
		/>
	</>

	const footer = fetched ? <></> : <>
		<div>{footerTexts.login.message} <b onClick={() => switchAuth(modalEnum.login)}>{footerTexts.login.linked}</b>
		</div>
		<div><b onClick={() => switchAuth(modalEnum.forgotPassword)}>{footerTexts.forgotPassword.linked}</b></div>
	</>

	return <>
		<AuthModal
			title={fetched ? '' : texts.title}
			header={header}
			main={main}
			footer={footer}
		/>
	</>
}

export default connect((store) => {
	return {
		register: store.register,
	};
})(SignupModal)