export function start() {
	return function (dispatch) {
		const list = JSON.parse(localStorage.getItem('products') || '[]')
		dispatch({type: 'cart_add', payload: list})
	}
}


export function add(product, quant = 1) {
	return function (dispatch, getState) {
		if (quant === 0) return

		let newCartItem = {product, quant}

		let newList = Array.isArray(getState().cart.items) ? getState().cart.items : []

		// If the product is already in the cart
		const foundItem = newList.map(item => item.product.id).indexOf(product.id)
		if (foundItem !== -1)
			newList[foundItem].quant = Number(newList[foundItem].quant) + Number(quant)
		else
			newList = [...newList, newCartItem]


		dispatch({type: 'cart_add', payload: newList})
		localStorage.setItem('products', JSON.stringify(newList))
	}
}

export function remove(id) {
	return function (dispatch, getState) {

		const newList = {
			...getState().cart,
			items: [...getState().cart.items.filter(item => item.product.id !== id)],
		}
		localStorage.setItem('products', JSON.stringify(newList.items))

		dispatch({type: 'cart_remove', payload: {id}})
	}
}