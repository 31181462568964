import React, {useState} from 'react'
import { connect } from 'react-redux'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import {routes, strings} from '../../strings'
import {Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, Typography} from '@material-ui/core'
import {Button} from '../form'
import style from './style'
import AddCartBtn from '../cart/AddCartBtn';
import DeleteCartItem from '../cart/DeleteCartItem';

const texts = strings.component.products.products

function ProductPreview(props) {
	const classes = style()
	const {
		content,
		content: {id, name, icon, description, oldPrice, price,},
		options: {limit,},
		cart: { items },
	} = props

	const isOnCart = !!items.filter(item => item.product.id === id).length
	const [bought, setBought] = useState(isOnCart)

	function switchBought(){
		setBought(!bought)
	}

	const overLimit = limit && description.length > limit
	const limitedDescription = overLimit ?
		`${description.substring(0, limit)}...`
		: description

	// Localization
	const localizationOptions = {style: 'currency', currency: strings.currency}
	const lOldPrice = oldPrice ? oldPrice.toLocaleString(strings.lang, localizationOptions) : null
	const lPrice = price ? price.toLocaleString(strings.lang, localizationOptions) : null

	return <>
		<Card>
			<Link to={routes.nav.product.view.href.replace(':id', id)}>
			<CardActionArea>
				<CardMedia
					className={classes.icon}
					image={icon}
					name={name}
					component='img'
				/>
				<CardContent>
					<Typography gutterBottom variant="h5" component="h2">
						{name}
					</Typography>
				</CardContent>
			</CardActionArea>
			</Link>
			<CardActions>
				<Grid container direction='row' justify='space-between' spacing={2}>
					<Grid item xs={3}>
						{bought ? <div onClick={switchBought}><DeleteCartItem id={id} /></div>
							: <div onClick={switchBought}><AddCartBtn iconOnly product={content}/></div>}
					</Grid>
					<Grid item xs={3}>
						<Button
							color='default'
							tooltip={texts.info.label}
							icon={texts.info.icon}
							to={routes.nav.product.view.href.replace(':id', id)}
						/>
					</Grid>
					<Grid container item xs={6} justify='flex-end' alignItems='flex-end'>
						<div className={classes.priceContainer}>
							{oldPrice && <div className={classes.oldPrice}>{lOldPrice}</div>}
							<div className={classes.price}>{lPrice}</div>
						</div>
					</Grid>
				</Grid>
			</CardActions>
		</Card>
	</>
}

ProductPreview.propTypes = {
	content: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		name: PropTypes.string,
		icon: PropTypes.string,
		description: PropTypes.string,
		oldPrice: PropTypes.number,
		price: PropTypes.number,
	}),
	options: PropTypes.shape({
		limit: PropTypes.number,
	}),
}

ProductPreview.defaultProps = {
	content: {
		id: 0,
		name: '',
		icon: '',
		description: '',
		oldPrice: 0,
		price: 0,
	},
	options: {
		limit: 65,
	},
}


export default connect(store => ({ cart: store.cart }), null)(ProductPreview)