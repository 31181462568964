import {strings} from '../../strings';
import {Avatar, Grid, ListItem, ListItemAvatar} from '@material-ui/core';
import React from 'react';
import {connect} from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Button} from '../form';
import {remove} from '../../actions/cart/cart';
import DeleteCartItem from './DeleteCartItem';

const useStyles = makeStyles(theme => ({
	cartItem: {
		textAlign: 'center',
	},
}))

const texts = strings.component.userCart

function CartItem(props) {
	const classes = useStyles()
	const {product: {id, icon: image, name: title, price}, quant,} = props

	// Localization
	const localizationOptions = {style: 'currency', currency: strings.currency}
	const totalPrice = (price * quant).toLocaleString(strings.lang, localizationOptions)
	return <>
		<ListItem button className={classes.cartItem}>
			<ListItemAvatar>
				<Avatar alt={title} src={image}/>
			</ListItemAvatar>
			<Grid container alignItems='center'>
				<Grid item xs={4}>
					{title}
				</Grid>
				<Grid item xs={2}>
					x{quant}
				</Grid>
				<Grid item xs={4}>
					{totalPrice}
				</Grid>
				<Grid item xs={2}>
					<DeleteCartItem id={id}/>
				</Grid>
			</Grid>
		</ListItem>
	</>
}

export default CartItem