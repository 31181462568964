import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {AppBar, Toolbar,} from '@material-ui/core'
import DesktopNav from './DesktopNav'
import SearchNav from '../common/SearchNav'
import MobileMenuNav from './MobileMenuNav'
import MobileNav from './MobileNav'
import Logo from '../common/Logo'
import HowToPlay from '../how_to_play/HowToPlay'

const useStyles = makeStyles(theme => ({
	marger: {
		height: '16px',
		[theme.breakpoints.up('md')]: {
			height: '80px',
		},
	},
	grow: {
		flexGrow: 1,
	},
	title: {
		display: 'none',
		margin: '12px 26px 12px 2px',
		[theme.breakpoints.up('md')]: {
			display: 'block',
		},
	},
}))


function Navbar() {
	const classes = useStyles()
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)

	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

	function handleMobileMenuClose() {
		setMobileMoreAnchorEl(null)
	}

	function handleMobileMenuOpen(event) {
		setMobileMoreAnchorEl(event.currentTarget)
	}

	return <>
		<div className={classes.grow}>
			<AppBar position='fixed'>
				<Toolbar>
					<div className={classes.title}>
						<Logo width='120px'/>
					</div>
					<HowToPlay/>
					<SearchNav/>
					<DesktopNav/>
					<MobileNav
						handleOpen={handleMobileMenuOpen}
					/>
				</Toolbar>
			</AppBar>
			<MobileMenuNav
				anchor={mobileMoreAnchorEl}
				open={isMobileMenuOpen}
				handleClose={handleMobileMenuClose}
			/>
		</div>
		<div className={classes.marger}/>
	</>
}

Navbar.propTypes = {}

export default Navbar