import React from 'react'
import Pagination from '../common/pagination/Pagination';
import {routes} from '../../strings';

function NewsPagination(props) {

	const {actual, total} = props

	return <>
		<Pagination link={routes.nav.home.paged.href}  actual={actual} total={total}/>
	</>
}

export default NewsPagination