export default {
	url: {
		api: {
			href: 'https://nsapi.ngsuit.com',
			buy: {
				href: '/purchase'
			},

			gateways: {
				href: '/shop/exposed/screen/gateways'
			},

			minecraft: {
				href: 'https://minotar.net/avatar/Franck_Cid'
			},

			profile: {
				href: '/shop/client/profile',
			},

			validateCoupon: {
				href: '/shop/coupon/validate/:id'
			},

			changepassword: {
				href: '/shop/client/changepassword',
			},

			history: {
				href: '/shop/client/purchase?pageSize=20',
			},
			register: {
				href: '/shop/client/register',
			},
			login: {
				href: '/shop/client/login',
			},
			recover: {
				href: '/shop/client/recovery',
			},
			reset: {
				href: '/shop/client/recovery/end',
			},
			categories: {
				categoriesList: {
					href: '/shop/exposed/screen/category',
				},
			},
			product: {
				shopList: {
					href: '/shop/exposed/screen/product?page=:page',
				},
				listByCategory: {
					href: '/shop/exposed/screen/category/:id/product?page=:page',
				},
				view: {
					href: '/shop/exposed/screen/product/:id',
				},
			},
			news: {
				list: {
					href: '/shop/exposed/screen/news',
				},
				view: {
					href: '/shop/exposed/screen/news/:id',
				},
			},
			shopId: 'screen',
		},
		widgets: {
			discord: {
				serverId: '530907295677415425',
				realUrl: 'https://discord.gg/SFz4KrW',
				widgetUrl: 'https://discordapp.com/widget?id=:id&theme=:theme'
			},
			twitter: {
				screenName: 'servidorscreen',
				realUrl: 'https://twitter.com/servidorscreen?lang=en',
			}
		}
	},
	nav: {
		home: {
			root: {
				href: '/'
			},
			paged: {
				href: '/news/:page'
			},
		},
		shop: {
			root: {
				href: '/shop'
			},
			paged: {
				href: '/shop/:page'
			},
			cat: {
				href: '/shop/:cat/:page'
			},
		},
		news: {
			view: {
				href: '/read/:id'
			},
		},
		product: {
			view: {
				href: '/product/:id'
			},
		},
		profile: {
			general: {
				href: '/profile'
			},
			requests: {
				href: '/requests'
			},
		},
		buying: {
			finish: {
				href: '/finish'
			},
		},
		signUp: {
			confirm: {
				href: '/confirmed/'
			}
		},
		recover: {
			finish: {
				href: '/passwordRecovery/:token'
			}
		},
		popups: [
			{
				url: '/passwordRecovery/',
				popupPage: 3
			}
		]
	},
}