import React from 'react'
import PropTypes from 'prop-types'
import {news} from '../../utility/prop_models'
import {NewsPreview} from '../../components/news'
import {strings} from '../../strings';
import {Grid, Paper} from '@material-ui/core';
import generic from '../../layout/generic';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
	noarticles: {
		padding: '32px',
		textAlign: 'center',
	}
}))

const text = strings.component.news.news

function NewsList(props) {
	const classes = useStyles()
	const {list, Component} = props

	if (!list || list.length <= 0)
		return <>
			<Paper>
				<main className={classes.noarticles}>{text.no_articles}</main>
			</Paper>
		</>

	return <>
		<Grid
			container
			direction='column'
			spacing={generic.spacing}
		>
			{list.map(item => <Grid item xs key={`article-${item.id}`}>
				<Component content={item}/>
			</Grid>)}
		</Grid>
	</>
}

NewsList.propTypes = {
	list: PropTypes.arrayOf(PropTypes.shape(news.types.content)),
	Component: PropTypes.func,
}

NewsList.defaultProps = {
	list: [],
	Component: NewsPreview,
}

export default NewsList