import React, {useState} from 'react'
import {Redirect} from 'react-router-dom'
import {ShopHeader} from '../../components/shop_header'
import {Product} from '../../components/product'
import OnePageLayout from '../../layout/onepage/OnePageLayout'
import {withSnackbar} from 'notistack'
import {connect} from 'react-redux'
import {getProduct} from '../../actions/product/product'
import {routes} from '../../strings'
import {Grid} from '@material-ui/core';
import Loading from '../../components/common/Loading';


function ProductView(props) {

	const {
		product: {actual, error, fetching},
		match: {params},
		get,
	} = props

	const [hasLoad, setHasLoad] = useState(false)
	if (!hasLoad) {
		setHasLoad(true)
		get(params.id)
	}

	const content = <>
		<Loading loading={fetching}/>
		{!fetching && <Product content={actual}/>}
		<Loading loading={fetching}/>
	</>

	return <>
		{error && <Redirect to={routes.nav.shop.root.href}/>}
		<ShopHeader/>
		<OnePageLayout Content={content}/>
	</>
}


const actions = (dispatch) => ({
	get: (id) => dispatch(getProduct(id)),
})

export default withSnackbar(connect(store => ({cart: store.cart, product: store.product}), actions)(ProductView))