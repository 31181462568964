import {LinearProgress} from '@material-ui/core';
import React from 'react';

function Loading(props) {
	const {loading} = props
	return <>
		{loading && <LinearProgress style={{width: '100%'}}/>}
	</>
}

export default Loading