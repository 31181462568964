import React from 'react'
import {Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import OnlinePlayers from '../common/OnlinePlayers';
import {strings} from '../../strings';
import PageNavbar from '../page_navbar/PageNavbar';

const texts = strings.component.header
const images = strings.image.header

const useStyles = makeStyles(theme => ({
	header: {
		padding: '32px',
		width: '100%',
		background: `linear-gradient(0deg, rgba(0, 0, 0, 0.62), rgba(0, 0, 0, 0.62)), url('${images.background.url}')`,
		backgroundSize: 'auto 100%',
		backgroundPosition: 'center',
		color: theme.palette.consonant.contrastText,
		[theme.breakpoints.up('md')]: {
			backgroundSize: '100%',
		},
	},
	logo: {
		width: '100%',
		margin: '16px',
		[theme.breakpoints.up('md')]: {
			width: '75%',
		},
	},
	text: {
		textAlign: 'justify',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '85%',
		},
	},
	h1: {
		fontWeight: 700,
		fontSize: '64px',
	},
	p: {
		fontSize: '24px',
		marginBottom: theme.spacing(2),
		[theme.breakpoints.up('md')]: {
			marginBottom: 0,
		},
	},
	onlinePlayers: {
		padding: '16px',
		backgroundColor: theme.palette.contrast.main,
		color: theme.palette.contrast.contrastText,
	},
}))

function Header() {
	const classes = useStyles()


	return <>

		<header>
			<Grid
				className={classes.header}
				container
				direction='row'
				justify='flex-start'
			>
				<Grid
					item
					xs={12}
					md={4}
				>
					<aside>
						<img
							className={classes.logo}
							src={images.logo.url}
							alt='logo'
						/>
					</aside>
				</Grid>
				<Grid
					container
					item
					xs={12}
					md={6}
					alignItems='center'
				>
					<aside className={classes.text}>
						<h1 className={classes.h1}>
							{texts.title.label}
						</h1>
						<p className={classes.p}>
							{texts.desc.label}
						</p>
					</aside>
				</Grid>
				<Grid
					container
					item xs={12}
					md={2}
					direction='column'
					justify='flex-end'
					alignItems='stretch'
				>
					<OnlinePlayers/>
				</Grid>
			</Grid>
		</header>
		<PageNavbar/>
	</>
}

// TODO: !!!! LOCALIZE
export default Header