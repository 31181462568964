import {routes} from '../../strings/'
import request from '../../utility/request';

const api = routes.url.api

export function getHistory() {
	return async function (dispatch) {
		dispatch(start())
		const response = await fetch(`${api.href}${api.history.href}`, {
			method: 'GET',
			headers: {'Authorization': `Bearer ${localStorage.token}`,}
		})
		const res = await response.json()
		console.log(response, res)
		if (response.ok) {
			dispatch(success(res))
		} else {
			dispatch(error(res.message))
		}
	}
}

export function start() {
	return {type: 'history_start'}
}

export function error(data) {
	return {type: 'history_error', payload: data}
}

export function success(data) {
	return {type: 'history_success', payload: data}
}
