import {routes} from '../../strings/'
import request from '../../utility/request';

const api = routes.url.api


export function doRegister(name, email, password, marketing = false) {
	return async function (dispatch) {
		dispatch(registerStart())

		const response = await request(`${api.href}${api.register.href}`, 'POST', {
			name,
			email,
			password,
			marketing,
		})
		const res = await response.json()
		if (response.ok) {
			dispatch(registerSuccess(res))
		} else {
			console.log(res)
			dispatch(registerError(res.message))
		}
	}
}


export function registerStart() {
	return {type: 'register_start'}
}

export function registerError(data) {
	return {type: 'register_error', payload: data}
}

export function registerSuccess(data) {
	return {type: 'register_success', payload: data}
}