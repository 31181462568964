import {Grid, makeStyles, TextField, Typography} from '@material-ui/core';
import React, {useState} from 'react';
import {Button} from '../form/'
import Loading from '../common/Loading';
import {routes} from '../../strings';
import {withSnackbar} from 'notistack';
import {connect} from 'react-redux';

const useStyles = makeStyles(theme => ({
	text: {
		textAlign: 'right',
		display: 'block',
		'& b': {
			color: theme.palette.primary.main,
		},
	},
	info: {
		margin: theme.spacing(4),
	},
	validateCoupon: {
		background: '#8f0',
		color: 'white'
	}
}))

function UserInfo(props) {
	const classes = useStyles()
	const {enqueueSnackbar} = props
	const {coupon, setCoupon} = props
	const {nickname, setNickname} = props
	const {CPF, setCPF} = props
	const {couponFound: success, setCouponFound: setSuccess} = props

	const [couponLoading, setCouponLoading] = useState(false)
	const [error, setError] = useState(false)

	function handleNickname(e) {
		setNickname(e.target.value)
	}

	function handleCPF(e) {
		setCPF(e.target.value)
	}

	function handleCoupon(e) {
		setCoupon(e.target.value)
	}

	async function testCoupon() {
		setCouponLoading(true)
		if(localStorage.token) {
			const options = {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${localStorage.token}`,
					'Content-Type': 'application/json'
				},
			}

			const res = await fetch(
				routes.url.api.href + routes.url.api.validateCoupon.href.replace(':id', coupon),
				options)
			const resJson = await res.json()
			console.log(options, resJson)
			if (res.ok) {
				setSuccess({...resJson, type: resJson.couponType,})
				enqueueSnackbar('Cupom válido! :)', {variant: 'success'})
				setError(false)
			}else{
				setSuccess(null)
				enqueueSnackbar('Cupom inválido! :(', {variant: 'error'})
				setError(true)
			}
		}else{
			enqueueSnackbar('Você precisa estar logado para fazer isso!', {variant: 'error'})
		}
		setCouponLoading(false)
	}

	return <>
		<Typography variant='h3' className={classes.title}>
			Primeiros passos
		</Typography>
		<section className={classes.info}>
			<Grid container justify='center' alignItems='center' spacing={8}>
				<Grid item xs={12} md={4}>
					<span className={classes.text}>
							Informe seu <b>nome de usuário do Minecraft</b> para
							que o item seja direcionado para sua conta
							após a efetuação do pagamento.
							</span>
				</Grid>
				<Grid item xs={12} md={5}>
					<TextField
						fullWidth
						required
						variant='outlined'
						label='Meu nickname'
						value={nickname}
						onChange={handleNickname}
						margin='normal'
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<span className={classes.text}>
							Caso você tenha um <b>cupom de desconto</b>,
							insira o código no campo ao lado.
						</span>
				</Grid>
				<Loading loading={couponLoading}/>
				<Grid item xs={12} md={4}>
					<TextField
						fullWidth
						error={error}
						variant='outlined'
						label='Cupom'
						onChange={handleCoupon}
						value={coupon}
						disabled={couponLoading}
						margin='normal'
					/>
					{error && <p>Cupom inválido! :(</p>}
					{success && <p>Cupom válido! :)</p>}
				</Grid>
				<Grid item xs={12} md={1}>
					<Button icon='check' fullWidth className={classes.validateCoupon}
							onClick={testCoupon}
							disabled={couponLoading}
					/>
				</Grid>
				<Loading loading={couponLoading}/>

				<Grid item xs={12} md={4}>
					<span className={classes.text}>
							Informe seu <b>CPF</b>.
							</span>
				</Grid>
				<Grid item xs={12} md={5}>
					<TextField
						fullWidth
						required
						variant='outlined'
						label='Meu CPF'
						value={CPF}
						onChange={handleCPF}
						margin='normal'
					/>
				</Grid>

			</Grid>
		</section>
	</>
}

export default withSnackbar(UserInfo)