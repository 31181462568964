import React from 'react'
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles'
import {CssBaseline} from '@material-ui/core/'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'

import Home from './pages/home/Home'
import {Navbar} from './components/navbar';
import {Footer} from './components/footer';
import NewsView from './pages/news/NewsView';
import {routes} from './strings';
import Shop from './pages/shop/Shop';
import ProductView from './pages/product/ProductView';
import NotFound from './pages/not_found/NotFound';
import TokenWatcher from './components/auth/TokenWatcher';
import {SnackbarProvider} from 'notistack';
import ProfileGeneral from './pages/profile/general/ProfileGeneral';
import RequestsGeneral from './pages/profile/requests/RequestsGeneral';
import Finish from './pages/finish/Finish';

const theme = createMuiTheme({
	palette: {
		error: {main: '#eb0800'},
		success: {main: '#1e6c00'},
		primary: {text: '#FE5710', main: '#EF6C00', light: '#FFCC80', contrastText: 'white', coolText: '#FF8A65'},
		secondary: {main: '#FF9800', dark: '#FEBB0E', contrastText: 'white',},
		consonant: {main: '#F9A825', contrastText: 'white',},
		contrast: {main: '#00E676', light: '#1DE9B6', contrastText: 'white',},
		different: {main: '#795548', contrastText: 'white',}
	},
	overrides: {
		MuiTooltip: {
			tooltip: {
				fontSize: '14px',
				color: 'white',
				fontWeight: 700,
				backgroundColor: '#00E676'
			}
		}
	},
	typography: {
		fontFamily: [
			'Montserrat',
			'Roboto',
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
		].join(','),
		useNextVariants: true
	},
})

function App() {
	return <>
		<MuiThemeProvider theme={theme}>
			<SnackbarProvider maxSnack={6}>
				<CssBaseline/>
				<Router>
					<TokenWatcher/>
					<Navbar/>
					<Switch>
						<Route path={routes.nav.signUp.confirm.href} component={Home}/>
						<Route path={routes.nav.recover.finish.href} component={Home}/>
						<Route path={routes.nav.home.root.href} exact component={Home}/>
						<Route path={routes.nav.home.paged.href} exact component={Home}/>
						<Route path={routes.nav.news.view.href} exact component={NewsView}/>
						<Route path={routes.nav.shop.root.href} exact
							   render={(props) => <Shop keyProp={Math.random()} key={Math.random()} {...props}/>}/>
						<Route path={routes.nav.shop.paged.href} exact
							   render={(props) => <Shop keyProp={Math.random()} key={Math.random()} {...props}/>}/>
						<Route path={routes.nav.shop.cat.href} exact
							   render={(props) => <Shop keyProp={Math.random()} key={Math.random()} {...props}/>}/>
						<Route path={routes.nav.profile.general.href} exact component={ProfileGeneral}/>
						<Route path={routes.nav.profile.requests.href} exact component={RequestsGeneral}/>
						<Route path={routes.nav.buying.finish.href} exact component={Finish}/>
						<Route path={routes.nav.product.view.href} exact component={ProductView}/>
						<Route component={NotFound}/>
					</Switch>
					<Footer/>
				</Router>
			</SnackbarProvider>
		</MuiThemeProvider>
	</>
}

export default App
