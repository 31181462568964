import React, {useState} from 'react'
import {News, NewsPagination} from '../../components/news'
import GenericPage from '../generic/GenericPage';
import {getNewsById} from '../../actions/news/news';
import {connect} from 'react-redux';
import Loading from '../../components/common/Loading';

function NewsView(props) {
	const {
		news: {actual, fetching, fetched},
		match: {params},
		history,
	} = props

	const [hasLoad, setHasLoad] = useState(false)
	if (!hasLoad) {
		setHasLoad(true)
		props.get(params.id)
	}

	console.log(actual)

	const content = <>
		<Loading loading={fetching}/>
		{fetched && <News content={actual}/>}
		<Loading loading={fetching}/>
	</>

	return <>
		<GenericPage Content={content}/>
	</>
}

const actions = (dispatch) => ({
	get: (id) => dispatch(getNewsById(id)),
})

export default connect(store => ({news: store.news}), actions)(NewsView)