import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import {IconButton,} from '@material-ui/core'
import {Button} from '../form';
import {strings} from '../../strings';

const useStyles = makeStyles(theme => ({
	sectionMobile: {
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
}))


function MobileNav(props) {
	const classes = useStyles()
	const {handleOpen} = props
	return (
		<div className={classes.sectionMobile}>
			<Button
				Component={IconButton}
				icon={strings.component.navbar.more.icon}
				tooltip={strings.component.navbar.more.label}
				onClick={handleOpen}
			/>
		</div>
	)
}

MobileNav.propTypes = {
	handleOpen: PropTypes.func,
}

export default MobileNav