const reducerName = 'login'
const actions = (state, payload = {}) => ({
	[`${reducerName}_start`]: {...state, fetched: false, fetching: true, error: null},
	[`${reducerName}_error`]: {...state, fetched: false, fetching: false, error: payload},
	[`${reducerName}_success`]: {...state, fetched: true, fetching: false, error: null},
	[`${reducerName}_token`]: {...state, token: payload},
	[`${reducerName}_clear`]: {...state, token: null},
})

export default function reducer(state = {
	fetching: false,
	fetched: false,
	error: null,
	token: null,
}, action) {
	return actions(state, action.payload)[action.type] || state
}