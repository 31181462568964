import React from 'react'
import {Link} from 'react-router-dom'
import {List, ListItem, ListItemText, Paper, Typography} from '@material-ui/core/'
import {makeStyles} from '@material-ui/core';
import {routes, strings} from '../../strings';


const useStyles = makeStyles(theme => ({}))


const texts = strings.component.loggedUser

function ProfileMenu(props) {
	const classes = useStyles()

	return <>
		<Paper>
			<List component='nav' aria-label='Secondary mailbox folder'>
				<Link to={routes.nav.profile.general.href}>
					<ListItem
						button
					>
						<ListItemText primary={texts.options.myProfile}/>
					</ListItem>
				</Link>
				<Link to={routes.nav.profile.requests.href}>
					<ListItem
						button
					>
						<ListItemText primary={texts.options.myRequests}/>
					</ListItem>
				</Link>
			</List>

		</Paper>
	</>
}

export default ProfileMenu