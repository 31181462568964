import React, {useState} from 'react'
import {BasicLayout} from '../../../layout'
import {List, ListItem, ListItemText, Paper, Typography} from '@material-ui/core/'
import {makeStyles, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {strings} from '../../../strings';
import {SimpleHeader} from '../../../components/simple_header';
import ProfileMenu from '../../../components/profile/ProfileMenu';
import {connect} from 'react-redux';
import {getHistory} from '../../../actions/history/history';
import Loading from '../../../components/common/Loading';


const useStyles = makeStyles(theme => ({
	content: {
		padding: theme.spacing(2),
	},
	title: {
		fontWeight: 300,
	},
	hr: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	text: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(3),
		display: 'block',
	},
	tableHeader: {
		fontSize: '18px',
		color: 'black',
	},
	noitems: {
		textAlign: 'center',
		margin: theme.spacing(8),
		fontSize: '36px',
	}
}))


function RequestsGeneral(props) {
	const classes = useStyles()
	const {
		history,
		match: {params},
	} = props
	const {content: list} = props.requests
	let content = null

	const {fetching: loading} = props.requests

	const [hasLoad, setHasLoad] = useState(false)
	if (!hasLoad) {
		setHasLoad(true)
		props.get()
	}

	history.listen((location, action) => {
		setHasLoad(false)
	})

	if(loading) {
		content = <div className={classes.noitems}>Carregando histórico...</div>
	} else if (!Array.isArray(list) || !list.length) {
		content = <div className={classes.noitems}>Nenhuma compra no histórico.</div>
	} else {

		content = <Paper className={classes.content}>
			<Typography variant='h3' className={classes.title}>
				Meus pedidos
			</Typography>
			<hr className={classes.hr}/>
			<Typography variant='p' className={classes.text}>
				Aqui, é o histórico de compras realizado por você!
			</Typography>
			<Paper>
				<Table className={classes.table}>
					<TableHead>
						<TableRow>
							<TableCell className={classes.tableHeader}>Método de pagamento</TableCell>
							<TableCell className={classes.tableHeader}>Status da compra</TableCell>
							<TableCell className={classes.tableHeader} align="right">Cupom</TableCell>
							<TableCell className={classes.tableHeader} align="right">Itens</TableCell>
							<TableCell className={classes.tableHeader} align="right">Valor total</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{list.map(i => <TableRow>
							<TableCell component="th" scope="row">
								{i.transaction.paymentGateway}
							</TableCell>
							<TableCell align="right">
								{strings.enums[i.transaction.paymentStatus]}
							</TableCell>
							<TableCell align="right">
								{i.couponResume && i.couponResume.name}
							</TableCell>
							<TableCell align="right">
								{i.productPurchaseInfoResumes.map(i => i.shortProductResume.name).join(', ')}
							</TableCell>
							<TableCell align="right">
								R${i.productPurchaseInfoResumes.reduce((curr, i) => curr + i.totalValue, 0).toFixed(2)}
							</TableCell>
						</TableRow>)}
					</TableBody>
				</Table>
			</Paper>
		</Paper>
	}

	return <>
		<SimpleHeader title={strings.page.profile.general.title}/>
		<Loading loading={loading}/>
		<BasicLayout
			spacing={2}
			Content={<ProfileMenu/>}
			Aside={content}
			rightSize={9}
		/>
		<Loading loading={loading}/>
	</>
}

const actions = (dispatch) => ({
	get: () => dispatch(getHistory()),
})
export default connect(store => ({requests: store.history}), actions)(RequestsGeneral)