import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import UserAuth from '../common/UserAuth';
import UserCart from '../cart/UserCart';

const useStyles = makeStyles(theme => ({
	sectionDesktop: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
}))


function DesktopNav() {
	const classes = useStyles()
	return (
		<div className={classes.sectionDesktop}>
			<UserAuth/><br/>
			<UserCart/>
		</div>
	)
}

DesktopNav.propTypes = {}

// TODO: !!!!!!!!!!!!!! LOCALIZE
export default DesktopNav