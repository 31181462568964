import React from 'react'
import PropTypes from 'prop-types'
import {routes} from '../../strings';

function DiscordWidget(props) {
	const {id, theme, width, height, allowtransparency, frameborder} = props

	let newUrl = routes.url.widgets.discord.widgetUrl
	newUrl = newUrl.replace(':id', id)
	newUrl = newUrl.replace(':theme', theme)

	return <>
		<iframe
			title={'eae'}
			src={newUrl}
			width={width}
			height={height}
			allowtransparency={allowtransparency}
			frameBorder={frameborder}
		></iframe>
	</>
}

DiscordWidget.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	theme: PropTypes.string,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	allowtransparency: PropTypes.string,
	frameborder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

DiscordWidget.defaultProps = {
	theme: 'dark',
	width: '100%',
	height: 500,
	allowtransparency: 'true',
	frameborder: 0,
}

export default DiscordWidget