import React from 'react'
import {Grid} from '@material-ui/core'
import style from './style'
import {strings} from '../../strings';
import PropTypes from 'prop-types';

const texts = strings.component.news.news

function NewsHeader(props) {
	const classes = style()
	const {title, author, datetime} = props

	const dt = new Date(datetime)
	const localizedDate = dt.toLocaleDateString(strings.lang)
	const localizedTime = dt.toLocaleTimeString(strings.lang, {hour: '2-digit', minute: '2-digit'})
	const postedDate = texts.postedDate
	.replace(':date', localizedDate)
	.replace(':time', localizedTime)

	return <>
		<header className={classes.header}>
			<Grid
				container
				direction='row'
				alignItems='center'
				justify='flex-start'
			>
				<Grid
					xs={12}
					md={2}
					item
					container
					direction='row'
					alignItems='center'
					justify='center'
					className={classes.authorContainer}
				>
					<img
						className={classes.authorPic}
						src={`https://minotar.net/avatar/${author}`}
						alt={title}
					/>
					<div className={classes.author}>{author}</div>
				</Grid>
				<Grid
					className={classes.textContainer}
					xs={12}
					md
					item
				>
					<h1 className={classes.title}>{title}</h1>
					<span
						className={classes.datetime}
					>
                                {postedDate}
                            </span>
				</Grid>
			</Grid>
		</header>
	</>
}

NewsHeader.propTypes = {
	title: PropTypes.string,
	datetime: PropTypes.number.isRequired,
	author: PropTypes.string,
	authorPic: PropTypes.string,
}


export default NewsHeader