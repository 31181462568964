import React from 'react'
import {Redirect} from 'react-router-dom'
import {Button, Grid, makeStyles} from '@material-ui/core'
import {Field} from 'formik'
import {TextField} from 'formik-material-ui'
import Loading from '../../common/Loading'
import {connect} from 'react-redux'
import ErrorFeedback from '../../common/ErrorFeedback'
import {routes, strings} from '../../../strings'

const useStyles = makeStyles(theme => ({
	modal: {
		padding: theme.spacing(1),
		textAlign: 'center',
	},
	title: {
		textAlign: 'center',
		fontWeight: 300,
		paddingTop: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		fontSize: '24px',
		color: 'gray',
	},
	button: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
}))

const texts = strings.form.login

function LoginForm(props) {
	const classes = useStyles()
	const {login: {fetching, fetched, error}} = props
	return <>
		{fetched && <Redirect to={routes.nav.home}/>}
		<Loading loading={fetching}/>
		<Grid container alignItems='center' justify='center'
			  className={classes.modal}
		>
			<Field
				{...texts.email}
				fullWidth
				name='email'
				type='email'
				margin='normal'
				variant='outlined'
				component={TextField}
			/>
			<Field
				{...texts.password}
				fullWidth
				name='password'
				type='password'
				margin='normal'
				variant='outlined'
				component={TextField}
			/>
			<ErrorFeedback message={error}/>
			<Button
				// fullWidth
				type='submit'
				className={classes.button}
				variant='contained'
				children={'Entrar'}
				color='secondary'
				// onClick={submitForm}
			/>
		</Grid>
		<Loading loading={fetching}/>
	</>
}

export default connect((store) => {
	return {
		login: store.login,
	};
})(LoginForm)