import React from 'react'
import {makeStyles} from '@material-ui/core/styles/index'
import {Icon, InputBase, Paper,} from '@material-ui/core/index'
import {strings} from '../../strings';

const useStyles = makeStyles(theme => ({
	search: {
		color: 'black',
		flexGrow: 1,
		position: 'relative',
		// borderRadius: '16px',
		backgroundColor: theme.palette.common.white,
		'&:hover': {
			backgroundColor: '#fafafa',
		},
		marginRight: theme.spacing(2),
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(3),
			width: 'auto',
		},
	},
	searchIcon: {
		width: theme.spacing(5),
		height: '100%',
		borderTopLeftRadius: 'inherit',
		borderBottomLeftRadius: 'inherit',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.secondary.contrastText,
	},
	inputRoot: {
		marginLeft: '8px',
		color: 'inherit',
		width: '100%',
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 7),
		transition: theme.transitions.create('width'),
		width: '100%',
	},
}))

const texts = strings.component.navbar.search

function SearchNav() {
	const classes = useStyles()

	return <>
		<Paper className={classes.search}>
			<div className={classes.searchIcon}>
				{/*<Icon children={'search'}/>*/}
			</div>
			{/*<InputBase*/}
			{/*	placeholder={texts.placeholder}*/}
			{/*	classes={{*/}
			{/*		root: classes.inputRoot,*/}
			{/*		input: classes.inputInput,*/}
			{/*	}}*/}
			{/*	inputProps={{'aria-label': texts.placeholder}}*/}
			{/*/>*/}
		</Paper>
	</>
}

SearchNav.propTypes = {}


// TODO: !!!!!!!!!!!!!! LOCALIZE
export default SearchNav