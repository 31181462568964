import makeStyles from '@material-ui/core/styles/makeStyles';
import {Grid} from '@material-ui/core';
import AssetIcon from '../common/AssetIcon';
import {strings} from '../../strings';
import React from 'react';

const useStyles = makeStyles(theme => ({
	modal: {
		// width: '330px',
		padding: theme.spacing(1),
		textAlign: 'center',
	},
	header: {
		padding: theme.spacing(2),
		textTransform: 'uppercase',
		background: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		clipPath: 'polygon(0 0, 100% 0, 100% 65%, 0% 100%)',
		'& h1': {
			fontWeight: 'black',
			fontSize: '44px',
		}
	},
	image: {
		padding: theme.spacing(1),
		transform: 'scaleX(-1)',
	},
	title: {
		textAlign: 'center',
		fontWeight: 300,
		paddingTop: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		fontSize: '24px',
		color: 'gray',
	},
	menuItem: {
		'&:hover': {
			color: theme.palette.primary.main,
		},
	},
	button: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	footer: {
		padding: theme.spacing(2),
		textAlign: 'center',
		'& b': {
			color: theme.palette.primary.coolText,
			fontWeight: 300,
			cursor: 'pointer',
		},
	}
}))

function AuthModal(props) {
	const classes = useStyles()
	const {title, header, main, footer,} = props
	return <>
		<header className={classes.header}>
			<h1>{title}</h1>
		</header>
		<Grid container alignItems='center'
			  className={classes.modal}
		>
			<Grid item sm={3}
				  className={classes.image}>
				<AssetIcon
					src={'https://i.imgur.com/kgzYW1S.png'}
					tooltip={strings.emotion.smile}
					width='100%'
				/>
			</Grid>
			<Grid item sm={9}>
				<header
					className={classes.list}
				>
					{header}
				</header>
				<main className={classes.main}>
					{main}
				</main>
				<footer className={classes.footer}>
					{footer}
				</footer>
			</Grid>
		</Grid>
	</>
}

export default AuthModal