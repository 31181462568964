import React from 'react';
import {connect} from 'react-redux';
import EmptyCart from './EmptyCart';
import FilledCart from './FilledCart';


function CartModal(props) {
	const {cart: {items},} = props
	return <>
		{items.length ? <FilledCart/> : <EmptyCart/>}
	</>
}

export default connect((store) => ({cart: store.cart,}))(CartModal)