import React, {useState} from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import {strings} from '../../strings'
import {Button} from '../form'
import {Avatar, Grid, IconButton, ListItem, ListItemAvatar,} from '@material-ui/core/'
import {connect} from 'react-redux';
import CartModal from './CartModal';
import {start} from '../../actions/cart/cart';

const useStyles = makeStyles(theme => ({
	userCart: {
		// display: 'none',
		// [theme.breakpoints.up('md')]: {
		// 	display: 'block',
		// },
	},
}))


const texts = strings.component.userCart

function UserCart(props) {
	const {cart: {items},} = props
	const classes = useStyles()

	const [loaded, setLoaded] = useState(false)

	if(!loaded) {
		props.start()
		setLoaded(true)
	}

	return <>
		<Button
			Component={IconButton}
			PopperContent={<CartModal/>}
			className={classes.userCart}
			tooltip={texts.btn.tooltip}
			icon={texts.btn.icon}
			iconSize='32px'
			badgeValue={ Array.isArray(items) ? items.reduce((acc, curr) => Number(acc) + Number(curr.quant), 0) : null}
		/>
	</>
}

UserCart.propTypes = {}

UserCart.defaultProps = {}

const actions = (dispatch) => ({
	start: () => dispatch(start()),
})


export default connect((store) => ({cart: store.cart,}),actions)(UserCart)