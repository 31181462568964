import React from 'react'
import PropTypes from 'prop-types'
import {routes, strings} from '../../strings'
import {news} from '../../utility/prop_models'
import {Grid, Paper} from '@material-ui/core'
import {Button} from '../form'
import style from './style'
import NewsContent from './NewsContent'
import NewsHeader from './NewsHeader'

const texts = strings.component.news.news

function PreviewNews(props) {
	const classes = style()
	const {
		content: {id, title, image, creationDate: datetime, content: description, author, authorPic,},
		options: {limit,}
	} = props


	return <>
		<Paper>
			<article>
				<NewsHeader
					title={title}
					datetime={datetime}
					author={author}
					authorPic={authorPic}
				/>
				<NewsContent
					image={image}
					description={description}
					limit={limit}
				/>
				<footer className={classes.footer}>
					<Grid container item direction='row' justify='flex-end'>
						<Button
							color='secondary'
							text={texts.see_more}
							to={routes.nav.news.view.href.replace(':id', id)}
						/>
					</Grid>
				</footer>
			</article>
		</Paper>
	</>
}

PreviewNews.propTypes = {
	content: PropTypes.shape(news.types.content),
	options: PropTypes.shape(news.types.options),
}

PreviewNews.defaultProps = {
	content: news.defaults.content,
	options: news.defaults.options,
}

export default PreviewNews