import React from 'react'
import PropTypes from 'prop-types'
import {TwitterTimelineEmbed} from 'react-twitter-embed'

function TwitterWidget(props) {
	const {screenName, width, height} = props

	return <>
		<TwitterTimelineEmbed
			sourceType='profile'
			screenName={screenName}
			options={{height, width,}}
		/>
	</>
}

TwitterWidget.propTypes = {
	screenName: PropTypes.string.isRequired,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

TwitterWidget.defaultProps = {
	width: '100%',
	height: 500,
}

export default TwitterWidget