import {MenuItem} from '@material-ui/core';
import React from 'react';
import {Link} from 'react-router-dom';
import {withSnackbar} from 'notistack';
import {connect} from 'react-redux';
import {routes} from '../../strings';

function SubMenu(props) {
	const {subcategories: cats, categories: {id, content: list}, catId} = props
	const subcategories = list.filter(i => id == i.parent_id)
	return <>
		{cats.map(cat => <Link to={routes.nav.shop.cat.href.replace(':cat', cat.id).replace(':page', 0)}
							   key={'mi-' + JSON.stringify(cat)}><MenuItem>{cat.name}</MenuItem></Link>)}
	</>
}

export default withSnackbar(connect(store => ({categories: store.categories}))(SubMenu))