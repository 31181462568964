import React, {useState} from 'react'
import {routes, strings} from '../../strings';
import {SimpleHeader} from '../../components/simple_header';
import {Grid, makeStyles, Paper} from '@material-ui/core';
import CartProducts from '../../components/finish/CartProducts';
import UserInfo from '../../components/finish/UserInfo';
import PaymentMethod from '../../components/finish/PaymentMethod';
import {Button} from '../../components/form';
import {withSnackbar} from 'notistack';
import Loading from '../../components/common/Loading';
import QRCode from "react-qr-code";


const useStyles = makeStyles(theme => ({
	content: {
		padding: theme.spacing(2),
		margin: theme.spacing(2),
	},
}))


const texts = strings.component.userCart

function Finish(props) {
	const classes = useStyles()
	const {enqueueSnackbar} = props

	const [selected, setSelected] = useState(null)
	const [loading, setLoading] = useState(false)

	const [qrcode, setQRCode] = useState('')
	const [nickname, setNickname] = useState('')
	const [CPF, setCPF] = useState('')
	const [coupon, setCoupon] = useState('')
	const [couponFound, setCouponFound] = useState(null)

	const cartItems = JSON.parse(localStorage.products || '[]')

	console.log('CART', cartItems)

	async function handlePurchase() {
		if (!nickname) {
			enqueueSnackbar('O campo de Nickname é obrigatório.', {variant: 'error'})
			return
		}

		if (cartItems.length <= 0) {
			enqueueSnackbar('Seu carrinho está vazio.', {variant: 'error'})
			return
		}

		if (!selected) {
			enqueueSnackbar('Por favor, selecione um método de pagamento.', {variant: 'error'})
			return
		}

		if(!CPF) {
			enqueueSnackbar('Digite um CPF válido no campo caso vá comprar por PIX, se não, digite 0.', {variant: 'error'})
			return
		} 

		if(!localStorage.token) {
			enqueueSnackbar('Por favor, faça login antes de comprar algo.', {variant: 'error'})
			return
		}
		
		enqueueSnackbar('Sua compra está sendo processada...')

		setLoading(true)

		const hasCoupon = couponFound ? {
			coupon: {
				code: couponFound.code
			}
		} : {}
		const options = {
			method: 'POST',
			headers: {
				'Authorization': `Bearer ${localStorage.token}`,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				transaction: {
					'paymentGateway': selected,
					'cpf': CPF
				},
				...hasCoupon,
				//cpf: CPF,

				'productPurchaseInfoList':
					[
						...cartItems.map(ci => ({
							product: ci.product,
							productAmount: ci.quant,
							// just ignore = , {"name":"cpf","value":"${CPF}"}
							arguments: `[{"name":"nickname","value":"${nickname}"}]`
						})),
					]
			})
		}
		
		const res = await fetch(routes.url.api.href + routes.url.api.buy.href, options)
		
		/*
		local tests
		const res = await fetch('http://localhost:8080' + routes.url.api.buy.href, options)
		*/
		
		const resJson = await res.json()
		console.log(options, resJson)
		if (res.ok) {
			localStorage.removeItem('products')

			if (resJson.paymentLink.startsWith("qrcode:")) {
				let code = resJson.paymentLink.replace("qrcode:", '')
				setQRCode(code);
				enqueueSnackbar('Escaneie o QRCode na tela para realizar o pagamento', {variant: 'success'})
			} else {
				enqueueSnackbar('Aguarde enquanto você está sendo redirecionado...', {variant: 'success'})
				window.location.href = resJson.paymentLink
			}
		} else {
			enqueueSnackbar('Opa... Algo deu errado. :(', {variant: 'error'})
			enqueueSnackbar(strings.error[resJson.message] || resJson.message, {variant: 'error'})
		}
		setLoading(false)

	}

	return <>
		<SimpleHeader title={strings.page.finish.title}/>
		<Paper className={classes.content}>
			<Loading loading={loading}/>
			<main>
				<UserInfo
					nickname={nickname} setNickname={setNickname}
					CPF={CPF} setCPF={setCPF}
					coupon={coupon} setCoupon={setCoupon}
					couponFound={couponFound} setCouponFound={setCouponFound}
				/>
				<CartProducts
					couponFound={couponFound}
					setCouponFound={setCouponFound}
				/>
				{qrcode === '' && <PaymentMethod selected={selected} setSelected={setSelected}/>}
				<Grid container item justify='center'>
					{qrcode === '' && <Button
						text={texts.endBuying.label}
						color={'secondary'}
						onClick={handlePurchase}
						disabled={loading}
						tooltip={localStorage.token ? '' : 'Você precisa estar logado para realizar uma compra.'}
					/>}
					{qrcode !== '' && <QRCode value={qrcode} size={400} />}
				</Grid>
			</main>
			<Loading loading={loading}/>
		</Paper>
	</>
}

export default withSnackbar(Finish)