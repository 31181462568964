import {routes} from '../../strings/'
import request from '../../utility/request';

const api = routes.url.api

export function getNews(type = 'root', page = 0) {
	return async function (dispatch) {
		dispatch(start())

		const choosenApi = api.news.list.href
		.replace(':page', page)

		const response = await request(`${api.href}${choosenApi}`)
		const res = await response.json()
		console.log(res)
		if (response.ok) {
			dispatch(success(res))
		} else {
			dispatch(error(res.message))
		}
	}
}
export function getNewsById(id) {
	return async function (dispatch) {
		dispatch(start())

		const response = await request(`${api.href}${api.news.view.href.replace(':id', id)}`)
		const res = await response.json()
		console.log(res)
		if (response.ok) {
			dispatch(view(res))
		} else {
			dispatch(error(res.message))
		}
	}
}

export function start() {
	return {type: 'news_start'}
}

export function error(data) {
	return {type: 'news_error', payload: data}
}

export function success(data) {
	return {type: 'news_success', payload: data}
}

export function view(data) {
	return {type: 'news_view', payload: data}
}
