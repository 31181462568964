import React from 'react';
import {connect} from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {strings} from '../../strings';

const useStyles = makeStyles(theme => ({
	emptyCart: {
		padding: theme.spacing(2),
		textAlign: 'center',
	},
	emptyMessage: {
		padding: theme.spacing(2),
	},
}))

const texts = strings.component.userCart

function EmptyCart(props) {
	const classes = useStyles()
	return <>
		<main className={classes.emptyCart}>
			<div className={classes.emptyMessage}>
				{texts.emptyCart}
			</div>
			{/*<Button*/}
			{/*	text={texts.emptyBtn.label}*/}
			{/*	color={'secondary'}*/}
			{/*/>*/}
		</main>
	</>
}

export default connect((store) => ({cart: store.cart,}))(EmptyCart)