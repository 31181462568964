import {
	Grid,
	makeStyles,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography
} from '@material-ui/core/index';
import AssetIcon from '../common/AssetIcon';
import {strings} from '../../strings';
import React from 'react';
import {connect} from 'react-redux';
import ProductItem from './ProductItem';
import Money from '../common/Money';

const useStyles = makeStyles(theme => ({
	products: {
		margin: `${theme.spacing(4)}px 0`,
	},
	tableHeader: {
		fontSize: '18px',
		color: 'black',
	},
	image: {
		padding: theme.spacing(4),
	},
	empty: {
		textAlign: 'center',
		padding: theme.spacing(4),
		display: 'block',
		width: '100%',
	},
	noitems: {
		textAlign: 'center',
		margin: theme.spacing(8),
		fontSize: '36px',
	}
}))

function CartProducts(props) {
	const classes = useStyles()
	const {cart: {items}} = props
	const {couponFound} = props

	if (!Array.isArray(items) || !items.length) return <div className={classes.noitems}>Nenhum item no carrinho.</div>

	function calcDiscount(subtotal, {type, value}){
		switch(type){
			case 'MONEY':
				return value
			case 'PERCENTAGE':
				return subtotal * (value/100)
			default: return 0
		}
	}

	const subtotal = items.reduce((acc, cur) => acc + (cur.product.price * cur.quant), 0)
	const descounts = calcDiscount(subtotal, couponFound || {type: 'MONEY', value: 0})
	const total = (subtotal - descounts)

	return <>

		<Typography variant='h3' className={classes.title}>
			Produtos no carrinho
		</Typography>
		<section className={classes.products}>
			<Grid container justify='center' alignItems='center'>
				<Grid item xs={12} md={3}
					  className={classes.image}>
					<AssetIcon
						src={strings.image.buying.cart}
						tooltip={strings.emotion.smile}
						width={'auto'}
						height={'200px'}
					/>
				</Grid>
				<Grid item xs={12} md={9}>
					<Paper>
						<Table className={classes.table}>
							<TableHead>
								<TableRow>
									<TableCell className={classes.tableHeader}>#</TableCell>
									<TableCell className={classes.tableHeader} align='right'>Item</TableCell>
									<TableCell className={classes.tableHeader} align='right'>Quantidade</TableCell>
									<TableCell className={classes.tableHeader} align='right'>Preço</TableCell>
									<TableCell/>
								</TableRow>
							</TableHead>
							<TableBody>
								{items.length > 0 ?
									items.map((i, index) =>
										<ProductItem
											key={`product-item-${i.product.name}-${i.product.id}`}
											item={i} index={index + 1}/>)
									: <div className={classes.empty}>
										O carrinho está vazio.
									</div>
								}

								<TableRow>
									<TableCell component='th' scope='row'/>
									<TableCell component='th' scope='row'/>
									<TableCell component='th' scope='row'/>
									<TableCell align='right'>Subtotal</TableCell>
									<TableCell align='right'><Money children={subtotal}/></TableCell>
								</TableRow>
								<TableRow>
									<TableCell component='th' scope='row'/>
									<TableCell component='th' scope='row'/>
									<TableCell component='th' scope='row'/>
									<TableCell align='right'>Descontos</TableCell>
									<TableCell align='right'><Money children={descounts}/></TableCell>
								</TableRow>
								<TableRow>
									<TableCell component='th' scope='row'/>
									<TableCell component='th' scope='row'/>
									<TableCell component='th' scope='row'/>
									<TableCell align='right'>Total</TableCell>
									<TableCell align='right'><Money children={total}/></TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</Paper>
				</Grid>
			</Grid>
		</section>
	</>
}

export default connect((store) => ({cart: store.cart,}))(CartProducts)
