import React, {useState} from 'react'
import {BasicLayout} from '../../../layout'
import {List, ListItem, ListItemText, Paper, Typography} from '@material-ui/core/'
import {Grid, makeStyles, TextField} from '@material-ui/core';
import AssetIcon from '../../../components/common/AssetIcon';
import {routes, strings} from '../../../strings';
import {SimpleHeader} from '../../../components/simple_header';
import ProfileMenu from '../../../components/profile/ProfileMenu';
import ResetPassword from './ResetPassword';


const useStyles = makeStyles(theme => ({
	content: {
		padding: theme.spacing(2),
	},
	title: {
		fontWeight: 300,
	},
	image: {
		padding: theme.spacing(2),
		transform: 'scaleX(-1)',
	},
}))


function ProfileGeneral(props) {
	const classes = useStyles()

	const [values, setValues] = useState({name: '', email: '',})

	async function get(){
		setHasLoad(true)
		const res = await fetch(routes.url.api.href+routes.url.api.profile.href, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${localStorage.token}`
			}
		})
		if(res.ok) {
			const response = await res.json();
			setValues(response)
		}
		console.log(res)
	}

	const [hasLoad, setHasLoad] = useState(false)
	if (!hasLoad) {
		get()
	}

	const content = <Paper className={classes.content}>
		<Typography variant='h3' className={classes.title}>
			Geral
		</Typography>
		<hr/>
		<Grid container alignItems='center'
			  className={classes.modal}>

			<Grid item sm={2}
				  className={classes.image}>
				<AssetIcon
					src={strings.image.common.steve}
					tooltip={strings.emotion.smile}
					width={'auto'}
					height={'200px'}
				/>
			</Grid>
			<Grid container item sm={10} spacing={10} justify='flex-start'>
				<Grid item>
					<TextField
						label='Nome'
						value={values.name}
						margin='normal'
					/>
				</Grid>
				<Grid item>
					<TextField
						label='Email'
						value={values.email}
						margin='normal'
					/>
				</Grid>
			</Grid>
			<Grid item xs>
				<ResetPassword/>
			</Grid>
		</Grid>
	</Paper>

	return <>
		<SimpleHeader title={strings.page.profile.general.title}/>
		<BasicLayout
			spacing={2}
			Content={<ProfileMenu/>}
			Aside={content}
			rightSize={9}
		/>
	</>
}

export default ProfileGeneral