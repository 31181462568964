import { Grid, MenuItem, MenuList, Paper } from '@material-ui/core';
import React from 'react';
import {Redirect, withRouter} from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { loginClear } from '../../actions/auth/login';
import { connect } from 'react-redux';
import { routes, strings } from '../../strings';
import { Link } from 'react-router-dom';
import { List } from '../profile/ProfileMenu';

const useStyles = makeStyles(theme => ({
	modal: {
		// width: '330px',
		padding: theme.spacing(1),
	},
	image: {
		padding: theme.spacing(1),
	},
	title: {
		textTransform: 'uppercase',
		paddingTop: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		fontSize: '24px',
		color: theme.palette.primary.main,
	},
	menuItem: {
		'&:hover': {
			color: theme.palette.primary.main,
		}
	},
}))

const texts = strings.component.loggedUser

function LoggedUser(props) {
	const classes = useStyles()
	const { clear } = props


	console.log('props', props)

	function logout() {
		props.history.push(routes.nav.home.root.href)
		clear()
	}

	return <>
		<Paper>
			<Grid container alignItems='center'
				className={classes.modal}>
				<Grid item sm={12}>
					<header
					>
						<h1
							className={classes.title}
						>
							{texts.welcome.replace(':name', localStorage.userName)}
						</h1>
					</header>
					<main>
						<MenuList>
							<Link to={routes.nav.profile.general.href}>
								<MenuItem
									className={classes.menuItem}
								>
									{texts.options.myProfile}
								</MenuItem>
							</Link>
							<Link to={routes.nav.profile.requests.href}>
								<MenuItem
									className={classes.menuItem}
								>
									{texts.options.myRequests}
								</MenuItem>
							</Link>
							<MenuItem
								className={classes.menuItem}
								onClick={logout}
							>
								{texts.options.logout}
							</MenuItem>
						</MenuList>
					</main>
				</Grid>
				{/*<Grid item sm={3}*/}
				{/*	  className={classes.image}>*/}
				{/*<AssetIcon*/}
				{/*	src={'https://i.imgur.com/edTX8Qx.png'}*/}
				{/*	tooltip={strings.emotion.smile}*/}
				{/*	width='100%'*/}
				{/*/>*/}
				{/*</Grid>*/}
			</Grid>
		</Paper>
	</>
}

const actions = dispatch => ({
	clear: () => dispatch(loginClear()),
})
export default withRouter(connect(() => ({}), actions)(LoggedUser))