import makeStyles from '@material-ui/core/styles/makeStyles';
import {strings} from '../../strings';
import {Grid} from '@material-ui/core';
import AssetIcon from '../common/AssetIcon';
import React from 'react';

const useStyles = makeStyles(theme => ({
	modal: {
		width: '400px',
		padding: theme.spacing(2),
	},
	howtoplay: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'block',
		},
	},
	image: {
		padding: theme.spacing(1),
	},
	list: {
		padding: theme.spacing(1),
		'& b': {
			color: theme.palette.primary.coolText,
			fontWeight: 'bold',
		}
	},
	ip: {
		textAlign: 'center',
	},
	footer: {
		textAlign: 'center',

	},
}))

const texts = strings.component.howtoplay

function HowToPlayModal() {
	const classes = useStyles()
	return <>
		<Grid container alignItems='center'
			  className={classes.modal}>
			<Grid item sm={3}
				  className={classes.image}>
				<AssetIcon
					src={texts.image}
					tooltip={strings.emotion.smile}
					width='100%'
				/>
			</Grid>
			<Grid item sm={9}>
				<header
					className={classes.list}
				>
					{texts.list.map(i => <div children={i}/>)}
				</header>
				<main className={classes.ip}>
					{texts.ip}
				</main>
				<footer className={classes.footer}>
					{texts.footer}
				</footer>
			</Grid>
		</Grid>
	</>
}

export default HowToPlayModal