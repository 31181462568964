import React from 'react'
import Button from '../../../components/form/Button';
import {Dialog} from '@material-ui/core';
import ResetPasswordModal from './ResetPasswordModal';


function ResetPassword() {

	return <>
		<Button PopperComponent={Dialog}
				PopperContent={<ResetPasswordModal/>}
				text='Redefinir senha'
				color='secondary'/>
	</>

}

export default ResetPassword