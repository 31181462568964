import React from 'react'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import {Badge, Button as MUButton, Icon, Popover, Tooltip,} from '@material-ui/core'
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
	button: {
		textDecoration: 'none',
	},
	iconOnly: {
		marginRight: '8px',
	},
	text: {
		fontWeight: 'bold'
	}
}))

function Button(props) {
	const classes = useStyles()
	const {
		PopperComponent,
		PopperContent,
		Component,
		to,
		icon,
		iconSize,
		text,
		color,
		variant,
		onClick,
		badgeValue,
		badgeColor,
		tooltip,
		openOnFirstRender,
		...others
	} = props

	// Handling link
	const MaybeLink = to ? Link : React.Fragment
	const maybeLinkProps = to ? {to, className: classes.button} : {}

	React.useEffect(() => {
		if(openOnFirstRender) {
			setBtnAnchor(true);
		}
	}, [])

	// Handling popper
	const [btnAnchor, setBtnAnchor] = React.useState(null);

	function handleClick(event) {
		setBtnAnchor(event.currentTarget);
	}

	function handleClose() {
		setBtnAnchor(null);
	}

	const open = Boolean(btnAnchor);
	const id = open ? 'simple-popover' : null;

	const onClickFunc = onClick ||
		(PopperContent ? handleClick : null)

	return <>
		<MaybeLink {...maybeLinkProps}>
			<Tooltip title={tooltip}>
				<Component
					aria-label={tooltip}
					onClick={onClickFunc}
					color={color}
					variant={variant}
					{...others}
				>
					<Badge
						badgeContent={badgeValue}
						color={badgeColor}
					>
						{icon &&
						<Icon
							style={{fontSize: iconSize}}
							className={text ? classes.iconOnly : null}
							children={icon}
						/>
						}
						<span
							className={classes.text}
							children={text}
						/>
					</Badge>
				</Component>
			</Tooltip>
		</MaybeLink>
		{PopperContent &&
		<PopperComponent
			id={id}
			open={open}
			anchorEl={btnAnchor}
			onClose={handleClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
		>
			{PopperContent}
		</PopperComponent>
		}
	</>
}

Button.propTypes = {
	PopperComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
	PopperContent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
	Component: PropTypes.object,
	icon: PropTypes.string,
	iconSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	text: PropTypes.string,
	color: PropTypes.string,
	to: PropTypes.string,
	variant: PropTypes.string,
	onClick: PropTypes.func,
	badgeValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	badgeColor: PropTypes.string,
	tooltip: PropTypes.string,
	openOnFirstRender: PropTypes.bool,
}

Button.defaultProps = {
	PopperComponent: Popover,
	PopperContent: null,
	Component: MUButton,
	onClick: null,
	icon: '',
	iconSize: '1.5rem',
	text: '',
	to: null,
	variant: 'contained',
	color: 'inherit',
	badgeValue: 0,
	badgeColor: 'secondary',
	tooltip: '',
	openOnFirstRender: false,
}

export default Button