import React from 'react'
import PropTypes from 'prop-types'
import {news} from '../../utility/prop_models';
import {Paper} from '@material-ui/core';
import NewsHeader from './NewsHeader';
import NewsContent from './NewsContent';

function News(props) {
	const {
		content: {id, title, image, creationDate: datetime, content: description, author,},
	} = props

	return <>
		<Paper>
			<article>
				<NewsHeader
					title={title}
					datetime={datetime}
					author={author}
				/>
				<NewsContent
					image={image}
					description={description}
				/>
			</article>
		</Paper>
	</>
}

News.propTypes = {
	content: PropTypes.shape(news.types.content)
}

News.defaultProps = {
	content: news.defaults.content,
}

export default News