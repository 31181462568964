import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
	pageTitle: props => ({
		textAlign: props.align,
		fontWeight: 300,
		fontSize: '64px',
		padding: theme.spacing(2),
		paddingBottom: 0,
		color: theme.palette.different.main,
	}),
}))

function PageTitle(props) {
	const classes = useStyles(props)
	const {title} = props
	return <>
		<h1
			className={classes.pageTitle}
		>
			{title}
		</h1>
	</>

}

PageTitle.propTypes = {
	title: PropTypes.string,
	align: PropTypes.string,
}

PageTitle.defualtProps = {
	title: '',
	align: 'left',
}

export default PageTitle