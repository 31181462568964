import {Button, Grid, makeStyles} from '@material-ui/core'
import React from 'react'
import {Field} from 'formik'
import {CheckboxWithLabel, TextField} from 'formik-material-ui'
import Loading from '../../common/Loading'
import {connect} from 'react-redux'
import ErrorFeedback from '../../common/ErrorFeedback';
import {strings} from '../../../strings';

const useStyles = makeStyles(theme => ({
	modal: {
		padding: theme.spacing(1),
		textAlign: 'center',
	},
	title: {
		textAlign: 'center',
		fontWeight: 300,
		paddingTop: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		fontSize: '24px',
		color: 'gray',
	},
	button: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
}))


const texts = strings.form.register

function SignupForm(props) {
	const classes = useStyles()
	const {register: {fetching, error}} = props
	return <>

		<Loading loading={fetching}/>
		< Grid container alignItems='center' justify='center'
			   className={classes.modal}
		>
			<Field
				{...texts.name}
				fullWidth
				name='name'
				margin='normal'
				variant='outlined'
				component={TextField}
			/>
			<Field
				{...texts.email}
				fullWidth
				name='email'
				type='email'
				margin='normal'
				variant='outlined'
				component={TextField}
			/>
			<Field
				{...texts.password}
				fullWidth
				name='password'
				type='password'
				margin='normal'
				variant='outlined'
				component={TextField}
			/>
			<Field
				{...texts.passwordConf}
				fullWidth
				name='passwordConf'
				type='password'
				margin='normal'
				variant='outlined'
				component={TextField}
			/>
			<Field
				name='marketing'
				color="primary"
				defaultChecked={true}
				Label={{label: texts.marketing.label}}
				component={CheckboxWithLabel}
			/>
			<ErrorFeedback message={error}/>
			<Button
				// fullWidth
				type='submit'
				className={classes.button}
				variant='contained'
				children={'Cadastrar'}
				color='secondary'
				// onClick={submitForm}
			/>
		</Grid>
		<Loading loading={fetching}/>
	</>
}

export default connect((store) => ({register: store.register,}))(SignupForm)