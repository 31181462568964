import React from 'react'

export default {
	lang: 'pt-BR',
	currency: 'BRL',
	image: {
		buying: {
			cart: 'https://i.imgur.com/wBhrdkX.png',
		},
		common: {
			steve: 'https://i.imgur.com/kgzYW1S.png',
		},
		header: {
			logo: {
				url: 'https://i.imgur.com/NW3lj8l.png',
			},
			background: {
				url: 'https://i.imgur.com/XASjzhj.jpg',
				// url: 'https://images.pexels.com/photos/689905/pexels-photo-689905.png?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
			},
		},
		shopHeader: {
			background: {
				url: 'https://i.imgur.com/XASjzhj.jpg',
				// url: 'https://images.pexels.com/photos/689905/pexels-photo-689905.png?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
			},
		},
		news: {
			avatarDefault: {
				url: '',
			},
			postDefault: {
				url: '',
			},
		},
	},
	emotion: {
		smile: ':)',
	},
	common: {
		name: 'Rede Screen',
	},
	validation: {
		required: 'Campo obrigatório.',
		min: 'Mínimo de ${min} caracteres.',
		max: 'Máximo de ${max} caracteres.',
		minNum: 'Mínimo de ${min}.',
		maxNum: 'Máximo de ${max}.',
		email: 'Endereço de email inválido.',
		identical: 'Ambos os campos devem coincidir.',
		document: 'Campo deve conter um código de CPF ou CNPJ válido.',
		phone: 'Campo deve conter um número de telefone válido.',
		dateFromPast: 'Data deve ser no passado.',
		dateFromFuture: 'Data deve ser no futuro.',
	},
	page: {
		home: {
			title: '',
		},
		shop: {
			title: 'Produtos em destaque!',
		},
		profile: {
			general: {
				title: 'Meu perfil'
			}
		},
		finish: {
			title: 'Finalizar compra',
		},
	},
	component: {
		news: {
			news: {
				see_more: 'Ver mais...',
				no_articles: 'Nenhum artigo publicado até o momento.',
				postedDate: 'Publicado em :date às :time',
				default: {
					content: {
						title: 'Sem título',
						descripion: 'Sem descrição.',
						author: 'Sem autor',
					},
					options: {
						limit: 316,
					}
				}
			}
		},
		products: {
			products: {
				see_more: 'Ver mais...',
				no_articles: 'Nenhum produto publicado até o momento.',
				addCart: {
					label: 'Adicionar ao carrinho',
					icon: 'shopping_cart',
				},
				info: {
					label: 'Ver detalhes',
					icon: 'info',
				},
				// postedDate: 'Publicado em :date às :time',
				default: {
					content: {
						title: 'Sem título',
						descripion: 'Sem descrição.',
						author: 'Sem autor',
					},
					options: {
						limit: 316,
					}
				}
			}
		},
		navbar: {
			more: { label: 'Ver mais', icon: 'more_vert' },
			search: { placeholder: 'O que você está procurando?', icon: 'more_vert' },
		},
		howtoplay: {
			image: 'https://i.imgur.com/wBhrdkX.png',
			list: [
				<span><b>1.</b> Abra na aba <b>multiplayer</b></span>,
				<span><b>2.</b> Clique na opção <b>conexão direta</b></span>,
				<span><b>3.</b> Insira nosso <b>IP</b>:</span>,
			],
			ip: 'redescreen.com',
			footer: 'Divirta-se! :)',
			btn: { label: 'Como jogar', tooltip: 'Está com dúvidas? Clique aqui! :)', icon: 'videogame_asset' },
		},
		userAuth: {
			btn: { tooltip: 'Login/Cadastro', loggedTooltip: 'Opções', icon: 'person' },
		},
		userCart: {
			btn: { tooltip: 'Carrinho de compras', icon: 'shopping_cart' },
			endBuying: { label: 'Finalizar Compra' },
			emptyBtn: { label: 'ir para loja', },
			emptyCart: 'Seu carrinho está vazio :(',
			title: 'Meu carrinho',
			successAdd: 'Produto adicionado ao carrinho.',
			deleteBtn: {
				icon: 'delete',
				tooltip: 'Excluir',
			},
		},
		onlineplayers: {
			message: {
				label: 'Jogadores',
				focus: 'online :)',
			}
		},
		header: {
			title: {
				label: 'BEM-VINDO!',
			},
			desc: {
				label: 'Seja muito bem-vindo ao nosso site! Fique por dentro das últimas notícias e adquira planos VIP e Cash!',
			},
		},
		shopHeader: {
			title: {
				label: 'NOSSA LOJA',
			},
			desc: {
				label: 'Bem-vindo a loja! Aqui você pode adquirir VIP e Cash.',
			},
		},
		loggedUser: {
			welcome: 'Olá, :name!',
			options: {
				myProfile: 'Meu perfil',
				myRequests: 'Meus pedidos',
				logout: 'Sair',
			}
		},
		authModalFooter: {
			signup: {
				message: 'Não possui uma conta?',
				linked: 'Cadastre-se agora',
			},
			login: {
				message: 'Já tem uma conta?',
				linked: 'Faça login!',
			},
			forgotPassword: {
				linked: 'Esqueci minha senha :(',
			},
		},
		loginModal: {
			title: 'Entrar',
			headerText: 'Já possui uma conta?',
		},
		signupModal: {
			title: 'Cadastrar',
			header: 'Junte-se a nós! :)',
			successHeader: 'Bem vindo a Rede Screen!',
			successMessagePending: 'Por favor, verifique sua caixa de email para efetivar sua conta.',
			successMessageComplete: 'Tudo pronto! agora basta você realizar o login!'
		},
		forgotPasswordModal: {
			title: 'Recuperar senha',
			header: 'Informe seu email',
			subheader: 'Para que você possa redefinir sua senha.',
		},
		finishRecoverModal: {
			title: 'Alterar senha',
			header: 'Informe uma nova senha',
			subheader: 'Para que você possa redefinir sua senha.',
		}
	},
	form: {
		login: {
			email: {
				label: 'Email',
				placholder: 'seuemail@gmail.com',
			},
			password: {
				label: 'Senha',
				placholder: '*senha segura*',
			},
		},
		register: {
			name: {
				label: 'Nome',
				placholder: 'Nome Bonito',
			},
			email: {
				label: 'Email',
				placholder: 'seuemail@gmail.com',
			},
			password: {
				label: 'Senha',
				placholder: '*senha segura*',
			},
			passwordConf: {
				label: 'Confirme sua senha',
				placholder: '*senha segura denovo*',
			},
			marketing: {
				label: 'Quero receber novidades no meu email.',
			},
		},
	},
	nav: {},
	error: {
		'Unauthorized': 'Por favor faça login antes de tentar fazer isso.',
		'Password mismatch': 'Verifique seus dados novamente.',
		'Client not found': 'Email ou senha errados, verifique novamente.',
		'Interval should be less than one year': 'Por favor, escolha um intervalo de tempo menor que um ano.',
		'User not found-header': 'Usuário não encontrado.',
		'User not found': 'Cheque suas informações e tente novamente.',
		'User already exists-header': 'Usuário já existe.',
		'User already exists': 'Este endereço de email já está sendo utilizado.',
		'Token not found-header': 'Token inválido.',
		'Token not found': 'Este link parece já ter expirado.',
		generic: 'Algo deu errado! D:',
	},
	enums: {
		'OPEN': 'Aguardando pagamento',
		'PROCESSING': 'Processando',
		'PAID': 'Pago',
		'AVALIABLE': 'Pago',
		'CANCELLED': 'Cancelado',
		'DISPUTE': 'Pedido de disputa',
		'DISPUTE_LOSS': 'Estornado',
		'UNKNOWN': 'Desconhecido',
	},
	tables: {},
}