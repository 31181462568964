import VerticalLayout from '../../layout/vertical/VerticalLayout';
import {Grid} from '@material-ui/core';
import {Button} from '../form';
import {routes, strings} from '../../strings';
import React from 'react';
import {connect} from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CartItem from './CartItem';

const useStyles = makeStyles(theme => ({
	cart: {
		padding: theme.spacing(2),
	},
	title: {
		fontWeight: 'bold',
		fontSize: '24px',
		color: theme.palette.primary.main
	},
	cartList: {
		textAlign: 'center',
		width: '400px',
		margin: `${theme.spacing(2)}px auto`,
	},
}))

const texts = strings.component.userCart

function FilledCart(props) {
	const classes = useStyles()
	// const cartList = JSON.parse(sessionStorage.getItem('products') || '[]')
	console.log(props.cart)
	const {cart: {items}} = props
	return <>
		<main className={classes.cart}>
			<h1 className={classes.title}>{texts.title}</h1>
			<main
				className={classes.cartList}
			>
				<VerticalLayout
					spacing={0}
					ContentList={items.map(i => <CartItem product={i.product} quant={i.quant}/>)}
				/>
			</main>
			<Grid container item justify='center'>
				<Button
					text={texts.endBuying.label}
					color={'secondary'}
					to={routes.nav.buying.finish.href}
				/>
			</Grid>
		</main>
	</>
}

export default connect((store) => ({cart: store.cart,}))(FilledCart)