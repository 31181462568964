import React, {useState} from 'react'
import {NewsList, NewsPagination} from '../../components/news'
import GenericPage from '../generic/GenericPage';
import {strings} from '../../strings';
import {getShop} from '../../actions/shop/shop';
import {connect} from 'react-redux';
import ProductList from '../../components/product/ProductList';
import {ProductPagination} from '../../components/product';
import Loading from '../../components/common/Loading';
import {getNews} from '../../actions/news/news';

function Home(props) {

	const {
		news: {totalPages, number, content: list, error, fetching},
		match: {params},
		history,
	} = props

	const [hasLoad, setHasLoad] = useState(false)
	if (!hasLoad) {
		setHasLoad(true)
		props.get('root', params.page || 0)
	}

	history.listen((location, action) => {
		setHasLoad(false)
	})
	console.log(list)

	const content = <>
		<Loading loading={fetching}/>
		<NewsList list={list}/>
		<NewsPagination total={totalPages} actual={number}/>
		<Loading loading={fetching}/>
	</>

	return <>
		<GenericPage pageTitle={strings.page.home.title} Content={content}/>
	</>
}

const actions = (dispatch) => ({
	get: (type = 'root', page = 0) => dispatch(getNews(type, page)),
})

export default connect(store => ({news: store.news}), actions)(Home)