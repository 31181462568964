import {routes} from '../../strings/'
import request from '../../utility/request';

const api = routes.url.api

export function getProduct(id) {
	return async function (dispatch) {
		dispatch(start())

		const response = await request(`${api.href}${api.product.view.href.replace(':id', id)}`)
		const res = await response.json()
		console.log(res)
		if (response.ok) {
			dispatch(success(res))
		} else {
			dispatch(error(res.message))
		}
	}
}

export function start() {
	return {type: 'product_start'}
}

export function error(data) {
	return {type: 'product_error', payload: data}
}

export function success(data) {
	return {type: 'product_success', payload: data}
}
