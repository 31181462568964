import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {Grid, makeStyles} from '@material-ui/core';
import PaginationItem from './PaginationItem'

const useStyles = makeStyles(theme => ({
	container: {
		marginTop: theme.spacing(2),
		textAlign: 'center',
	},
	noPagination: {
		margin: theme.spacing(4),
	}
}))

function Pagination(props) {
	const classes = useStyles()

	const {total, actual, limit, link} = props

	const firstPage = 0
	const limitRange = parseInt(limit / 2)
	const minorPage = Math.max(actual - limitRange, firstPage)
	const majorPage = Math.min(actual + limitRange, total)

	const iterationArray = []
	for (let i = minorPage; i < majorPage; i++) {
		iterationArray.push(i)
	}

	const leftPage = !(minorPage <= firstPage) && <>
		<PaginationItem num={firstPage} link={link}/>
		<PaginationItem disabled/>
	</>
	const rightPage = !(majorPage >= total) && <>
		<PaginationItem disabled/>
		<PaginationItem num={total} link={link}/>
	</>

	const listed = iterationArray.map(
		i => <PaginationItem
			key={`pagination-${i}`}
			num={i}
			active={i === actual}
			link={link}
		/>)

	if (total <= 1) return <div className={classes.noPagination}/>
	return <>
		<Grid
			item
			container
			justify='center'
			alignItems='center'
			className={classes.container}
		>
			{leftPage}
			{listed}
			{rightPage}
		</Grid>
	</>
}

Pagination.propTypes = {
	link: PropTypes.string,
	total: PropTypes.number,
	actual: PropTypes.number,
	limit: PropTypes.number,
}

Pagination.defaultProps = {
	link: ':page',
	total: 0,
	actual: 0,
	limit: 5,
}

export default Pagination