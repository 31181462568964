import {routes} from '../../strings/'
import request from '../../utility/request';

const api = routes.url.api

export function doLogin(email, password) {
	return async function (dispatch) {
		dispatch(loginStart())

		const response = await request(`${api.href}${api.login.href}`, 'POST', {
			email,
			password,
		})
		const res = await response.json()
		if (response.ok) {
			dispatch(loginSuccess())
			dispatch(loginToken(res.token))
			localStorage.userName = res.name
		} else {
			console.log(res)
			dispatch(loginError(res.message))
		}
	}
}

export function loginStart() {
	return {type: 'login_start'}
}

export function loginError(data) {
	return {type: 'login_error', payload: data}
}

export function loginSuccess() {
	return {type: 'login_success'}
}

export function loginToken(token) {
	localStorage.setItem('token', token)
	return {type: 'login_token', payload: token}
}

export function loginClear() {
	localStorage.removeItem('token')
	return {type: 'login_clear'}
}