import React from 'react'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import {Link} from 'react-router-dom';
import {Tooltip} from '@material-ui/core';
import {routes, strings} from '../../strings';


const useStyles = makeStyles(theme => ({
	logo: {
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		},
	},
}))

function Logo(props) {
	const {width, tooltip,} = props
	const classes = useStyles({width})

	return <>
		<Link to={routes.nav.home.root.href}>
			<Tooltip title={tooltip}>
				<img
					className={classes.logo}
					src={require('../../assets/logo/logo-white.svg')}
					alt='logo'
					width={width}
				/>
			</Tooltip>
		</Link>
	</>
}

Logo.propTypes = {
	tooltip: PropTypes.string,
	width: PropTypes.string,
}

Logo.defaultProps = {
	tooltip: strings.emotion.smile,
	width: '100px',
}

export default Logo