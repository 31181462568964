import React from 'react'
import {strings} from '../../strings';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Grid} from '@material-ui/core';
import AssetIcon from '../../components/common/AssetIcon';


const useStyles = makeStyles(theme => ({
	modal: {
		padding: theme.spacing(2),
		textAlign: 'center',
	},
	howtoplay: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'block',
		},
	},
	image: {
		padding: theme.spacing(1),
		transform: 'scaleX(-1)',
	},
	main: {
		padding: theme.spacing(1),
		'& b': {
			color: theme.palette.primary.coolText,
			fontWeight: 'bold',
		},
		'& h1': {
			fontSize: '240px',
			color: theme.palette.primary.main,
		},
	},
	footer: {},
}))

function NotFound() {
	const classes = useStyles()
	return <>
		<Grid container alignItems='center' justify='center'
			  className={classes.modal}>
			<Grid item xs={12} sm={4}
				  className={classes.image}>
				<AssetIcon
					src={'https://i.imgur.com/XwklYOf.png'}
					tooltip={strings.emotion.smile}
					height='70vh'
					width='auto'
				/>
			</Grid>
			<Grid item xs={12} sm={8}>
				<main
					className={classes.main}
				>
					<div><h1>404</h1></div>
					<div>Essa página não existe :(</div>
				</main>
				<footer className={classes.footer}>
					Tente novamente...
				</footer>
			</Grid>
		</Grid>
	</>
}

export default NotFound