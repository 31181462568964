import React from 'react'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import {Link} from 'react-router-dom';
import {Tooltip} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
	socialIcon: props => ({
		width: props.width,
		height: props.height,
	}),
}))

function AssetIcon(props) {
	const {icon, width, height, to, href, tooltip, src} = props
	const classes = useStyles({width, height})

	const MaybeLink = to ? Link : React.Fragment
	const maybeProps = to ? {to} : {}

	const MaybeHref = href ? 'a' : 'div'
	const hrefProps = href ? {href} : {}
	return <>
		<MaybeLink {...maybeProps}>
			<MaybeHref {...hrefProps}>
				<Tooltip title={tooltip}>
					<img
						className={classes.socialIcon}
						src={src ? src : require(`../../assets/icons/${icon}.svg`)}
						alt={icon}
					/>
				</Tooltip>
			</MaybeHref>
		</MaybeLink>
	</>
}

AssetIcon.propTypes = {
	to: PropTypes.string,
	href: PropTypes.string,
	src: PropTypes.string,
	icon: PropTypes.string,
	tooltip: PropTypes.string,
	width: PropTypes.string,
	height: PropTypes.string,
}

AssetIcon.defaultProps = {
	to: null,
	href: null,
	src: '',
	icon: '',
	tooltip: '',
	width: '48px',
	height: 'auto',
}

export default AssetIcon