import React from 'react'
import PropTypes from 'prop-types'
import style from './style'
import './../common/htmlView.css'

function NewsContent(props) {
	const classes = style()
	const {image, description, limit} = props

	const overLimit = limit && description.length > limit
	const limitedDescription = overLimit ?
		`${description.substring(0, limit)}...`
		: description

	return <>
		<section className={classes.image}>
			{image && <img src={image} alt={description}/>}
		</section>
		<main className={classes.text}>
			<div dangerouslySetInnerHTML={{
				__html:limitedDescription
			}} />
		</main>
	</>
}

NewsContent.propTypes = {
	image: PropTypes.string,
	description: PropTypes.string,
	limit: PropTypes.number,
}

NewsContent.defaultProps = {
	image: '',
	description: '',
	limit: 0,
}

export default NewsContent