import React from 'react'
import {Grid, TextField} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Button} from '../form';
import {modalEnum} from './AuthManager';
import AuthModal from './AuthModal';
import {routes, strings} from '../../strings';
import {connect} from 'react-redux';
import Loading from '../common/Loading';
import {doRecover} from "../../actions/auth/recover";
import {useParams} from "react-router-dom";
import request from "../../utility/request";

const useStyles = makeStyles(theme => ({
	modal: {
		// width: '330px',
		padding: theme.spacing(1),
		textAlign: 'center',
	},
	title: {
		textAlign: 'center',
		fontWeight: 300,
		paddingTop: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		fontSize: '24px',
		color: 'gray',
	},
	button: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	feedback: {
		margin: theme.spacing(1),
		textAlign: 'center',
		color: theme.palette.success.main,
		width: '100%',
		fontSize: '24px',
	},
	errorFeedback: {
		margin: theme.spacing(1),
		textAlign: 'center',
		color: theme.palette.error.main,
		width: '100%',
		fontSize: '24px',
	}
}))

const texts = strings.component.finishRecoverModal
const footerTexts = strings.component.authModalFooter

function FinishRecoverModal(props) {
	const classes = useStyles()
	const {switchAuth, dispatch} = props
	const [success, setSuccess] = React.useState(false)
	const [error, setError] = React.useState(false)
	const [expired, setExpired] = React.useState(false)
	const [fetching, setFetching] = React.useState(false)

	const onSubmit = (e) => {
		e.preventDefault()
		const data = new FormData(e.currentTarget);
		let password = data.get('password')
		let token = window.location.pathname.split("/")[2]
		setFetching(true)
		request(`${routes.url.api.href}/shop/client/recovery/end`, 'POST', {
			token, password
		}).then(async response => {
			setFetching(false)
			if (response.ok) {
				setSuccess(true)
				setTimeout(() => {
					window.location.href = "/"
				}, 1500)
			}else{
				let data = await response.json()
				if(data.message === "Recovery client not found") {
					setError("Este link de renovação já expirou!")
					setExpired(true)
					setTimeout(() => {
						window.location.href = "/"
					}, 1500)
				}else{
					setError("Um erro desconhecido ocorreu!")
				}
			}
		}).catch(async response => {
			setFetching(false)
			setError("Um erro desconhecido ocorreu!")
		})
	}

	const main = <>
		<Loading loading={fetching}/>
		<form
			onSubmit={onSubmit}
		>
			<Grid container alignItems='center' justify='center'
						className={classes.modal}
			>
				{error && <p className={classes.errorFeedback}>{error}</p>}
				{success && <p className={classes.feedback}>Sua senha foi alterada!.</p>}
				{(!success && !expired) && <>
						<TextField
						id={"password"}
						required
						type={'password'}
						inputProps={{
							minlength: 3
						}}
						fullWidth
						label='Senha'
						name="password"
						placeholder='Digite uma senha bem forte!'
						margin='normal'
						variant='outlined'
					/>
					<Button
						type={"submit"}
						className={classes.button}
						text={'Enviar'}
						color='secondary'
						disabled={fetching}
					/>
				</>}
			</Grid>
		</form>
	</>

	const header = (!success && !expired) ? <>
		<h1 className={classes.title}>
			{texts.header}
		</h1>
		<p>{texts.subheader}</p>
	</> : <></>

	const footer = (!success && !expired) ? <>
		<div>{footerTexts.login.message} <b onClick={() => switchAuth(modalEnum.login)}>{footerTexts.login.linked}</b>
		</div>
		<div>{footerTexts.signup.message} <b
			onClick={() => switchAuth(modalEnum.signup)}>{footerTexts.signup.linked}</b></div>
	</> : <></>

	return <>
		<AuthModal
			title={texts.title}
			header={header}
			main={main}
			footer={footer}
		/>
	</>
}

export default connect((store) => {
	return {
		recover: store.recover,
	};
})(FinishRecoverModal)