import React from 'react'
import {strings} from '../../strings'
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	error: {
		margin: theme.spacing(1),
		textAlign: 'center',
		color: theme.palette.error.main,
		width: '100%',
	},
}))

function ErrorFeedback(props) {
	const classes = useStyles()
	const {message} = props
	const translatedError = strings.error[message] || strings.error.generic
	return <>
		{message && <span className={classes.error}>{translatedError}</span>}
	</>
}

export default ErrorFeedback