import React, {useState} from 'react'
import { withRouter } from 'react-router-dom';
import {Redirect} from 'react-router-dom'
import {routes, strings} from '../../strings';
import {ShopHeader} from '../../components/shop_header';
import PageTitle from '../../components/common/PageTitle';
import ProductList from '../../components/product/ProductList';
import {ProductPagination} from '../../components/product';
import OnePageLayout from '../../layout/onepage/OnePageLayout';
import {withSnackbar} from 'notistack';
import {connect} from 'react-redux';
import {getShop} from '../../actions/shop/shop';
import Loading from '../../components/common/Loading';

function Shop(props) {

	const {
		shop: {totalPages, number, content: list, error, fetching},
		match: {params},
		history,
	} = props

	const [hasLoad, setHasLoad] = useState(false)
	if (!hasLoad) {
		setHasLoad(true)
		props.get(params.cat ? 'category' : 'root', params.page || 0, params.cat || null)
	}

	history.listen((location, action) => {
		// setHasLoad(false)
	})

	const content = <>
		<Loading loading={fetching}/>
		<ProductList list={list}/>
		<ProductPagination total={totalPages} actual={number} categoryId={params.cat ? params.cat : null}/>
		<Loading loading={fetching}/>
	</>

	return <>
		{error && <Redirect to={routes.nav.shop.root.href}/>}
		<ShopHeader/>
		<PageTitle title={strings.page.shop.title} align={'center'}/>
		<OnePageLayout Content={content}/>
	</>
}

const actions = (dispatch) => ({
	get: (type = 'root', page = 0, cat = null) => dispatch(getShop(type, page, cat)),
})

export default connect(store => ({shop: store.shop}), actions)(Shop)