import React from 'react'
import {Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import AssetIcon from '../common/AssetIcon'
import {routes} from '../../strings';
import Logo from '../common/Logo';


const useStyles = makeStyles(theme => ({
	footer: {
		padding: '32px',
		width: '100%',
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
	},
	copyright: {
		padding: theme.spacing(1),
		backgroundColor: theme.palette.different.main,
		color: theme.palette.different.contrastText,
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(2),
		},
	},
}))

function Footer() {
	const classes = useStyles()

	return <>

		<footer>
			<Grid
				className={classes.footer}
				container
				direction='row'
				justify='space-between'
				alignItems='center'
			>
				<Grid item xs>
					<aside>
						<Logo width='200px'/>
					</aside>
				</Grid>
				<Grid item xs={8}>
					<aside>
						<Grid container direction='row' justify='flex-end' alignItems='center' spacing={4}>
							<Grid item>
								<AssetIcon
									tooltip={'Twitter'}
									icon={'twitter'}
									href={routes.url.widgets.twitter.realUrl}
								/>
							</Grid>
							<Grid item>
								<AssetIcon
									tooltip={'Discord'}
									icon={'discord'}
									href={routes.url.widgets.discord.realUrl}
								/>
							</Grid>
						</Grid>
					</aside>
				</Grid>
			</Grid>
			<main className={classes.copyright}>
				<span>© Copyright 2023 | TODOS OS DIREITOS RESERVADOS. | A Screen Network não é, de maneira alguma, afiliado à ou endossado pela Mojang</span>
			</main>

		</footer>


	</>
}

// TODO: !!!! LOCALIZE
// TODO: !!!! MAYBE make the social network footer selection automatic by mapping strings.js
export default Footer