import {routes} from '../strings';

export default (href, method = 'GET', body = null, headers = null) => {
	const hasBody = body ? {
		body: JSON.stringify({
			...body, shop: {
				id: routes.url.api.shopId
			}
		}),
	} : {}
	const hasHeader = headers || (body ? {
		headers: {
			// 'Accept': 'application/json',
			'Content-Type': 'application/json'
		}
	} : {})
	const options = {
		method: method,
		...hasBody,
		...hasHeader,
	}
	return fetch(href, options)
}